/* eslint-disable no-nested-ternary */
import React, { useContext, useRef } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { BigButton } from '@/components/big-button';
import { DeviceType, WidgetType } from '@/utils/definitions';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PreviewPopoverContent } from '@/features/editor/widgets/shared/PreviewPopover';
import { usePreviewDetails } from '@/features/editor/widgets/shared/usePreviewDetails';
import { UndoRedoBtns } from '@/components/undo-redo-btns';
import { GlossyWrapper } from '@/components/glossy-wrapper';
import { handleInvalidAlloc } from '@/features/editor/widgets/targeting/shared';

export function useWidgetControls(onNextStep: () => void, nextTitle: string) {
  const {
    devicePreview,
    experienceControls,
    experienceState: { currentExperience },
  } = useContext(EditorContext);
  const {
    editorState: { device },
  } = devicePreview;
  const nextRef = useRef(null);

  const onSave = async () => {
    if (handleInvalidAlloc(currentExperience)) {
      return;
    }
    await experienceControls.save();
  };

  const onPreview = async (url: string, variant?: string) =>
    experienceControls.preview(url, variant);

  const onSharePreview = async (
    email: string,
    sharedBy: string,
    quickPreviewUrl?: string,
  ) => experienceControls.sharePreview(email, sharedBy, quickPreviewUrl);

  return {
    controlsProps: {
      device,
      onSave,
      onPreview,
      onSharePreview,
      onNextStep,
      nextTitle,
      nextRef,
    } as WidgetControlsProps,
    WidgetControls,
  };
}

export interface WidgetControlsProps {
  device: DeviceType;
  onSave: () => Promise<void>;
  onPreview: (string?) => Promise<string>;
  onSharePreview: (
    email: string,
    sharedBy: string,
    quickPreviewUrl?: string,
  ) => Promise<void>;
  onNextStep: () => Promise<void>;
  nextTitle: string;
  nextRef: React.MutableRefObject<HTMLElement>;
  showHistory?: boolean;
  hidePreview?: boolean;
}

export function WidgetControls({
  device,
  onSave,
  onPreview,
  onSharePreview,
  onNextStep,
  nextTitle,
  nextRef,
  showHistory,
  hidePreview,
}: WidgetControlsProps) {
  const {
    inspectorNav: { currentWidget },
    experienceControls: { hasUnsavedChanged, isNewAndUnsaved },
    experienceState,
  } = useContext(EditorContext);
  const { togglePopover, quickPreviewUrl, previewPopoverOpen } =
    usePreviewDetails();

  const shouldShowHistory = showHistory;
  const hasChanges = experienceState?.getCurrentVariant()?.changes?.length > 0;
  const hasHistory = experienceState?.canRedo || experienceState?.canUndo;

  if (
    !hasChanges &&
    !hasHistory &&
    currentWidget.kind === WidgetType.CHANGELOG
  ) {
    return null;
  }

  return (
    <Footer showHistory={shouldShowHistory} hidePreview={hidePreview}>
      {shouldShowHistory && (
        <UndoRedoBtns
          redo={experienceState.redo}
          undo={experienceState.undo}
          canUndo={experienceState.canUndo}
          canRedo={experienceState.canRedo}
        />
      )}
      <BigButton
        disabled={!hasUnsavedChanged()}
        border="2px solid #C8CACB"
        background="white"
        color="#97A0A8"
        noShadow
        noTransform
        size={device === DeviceType.Desktop ? `medium-thin` : `medium`}
        fillWidth
        onClick={onSave}
      >
        Save
      </BigButton>
      {!hidePreview && (
        <Popover
          isOpen={previewPopoverOpen}
          positions={[`top`]}
          onClickOutside={togglePopover}
          containerStyle={{ zIndex: `9999` }}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="white"
              arrowSize={20}
              arrowStyle={{ opacity: 0.5 }}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <PreviewPopoverContent
                onPreview={onPreview}
                onSharePreview={onSharePreview}
                quickPreviewUrl={quickPreviewUrl}
                togglePopover={togglePopover}
                variants={experienceState.currentExperience.variants}
              />
            </ArrowContainer>
          )}
        >
          <BigButton
            border="2px solid #C8CACB"
            background="white"
            color="#97A0A8"
            noShadow
            noTransform
            size={device === DeviceType.Desktop ? `medium-thin` : `medium-high`}
            fillWidth
            onClick={togglePopover}
          >
            Preview
          </BigButton>
        </Popover>
      )}

      <BigButton
        disabled={
          isNewAndUnsaved() && nextTitle?.toLocaleLowerCase() === `publish`
        }
        ref={nextRef}
        onClick={onNextStep}
        border="2px solid black"
        noTransform
        size={device === DeviceType.Desktop ? `medium-thin` : `medium-high`}
        fillWidth
      >
        {nextTitle}
      </BigButton>
    </Footer>
  );
}

type FProps = { showHistory: boolean; hidePreview: boolean };
const Footer = styled(GlossyWrapper)`
  && {
    margin-left: -1rem;
    margin-bottom: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: ${(p: FProps) =>
      p.hidePreview
        ? `1fr 2fr`
        : p.showHistory
        ? `0.5fr 1fr 1fr 2fr`
        : `1fr 1fr 2fr`};
    grid-column-gap: 2rem;
  }
`;
