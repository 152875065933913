import {
  QBItemProp,
  QBItemProps,
  QBItemSelection,
  QBType,
} from '@/components/query-builder/models';
import {
  hasVariantPrimaryAttrName,
  LoadedEditorResources,
} from '@/features/editor/use-editor-loader';
import { SelectOptions } from '@/components/small-select';
import { TextValueOps } from '@/components/query-builder/text-value';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { removeDuplicatesByKey } from '@/utils/arrays';
import { isHidingTheOldPageUrlTargetingSupported } from '@/webapi/experience-creation-version';
import { getProductValue } from '@/features/editor/widgets/shared/modals/use-audience-query-builder';
import { NumberValueOps } from '@/components/query-builder/numeric-value';

export function usePlacementQueryBuilder(
  resources: LoadedEditorResources,
  experienceCreationVersion: number,
  other?: QBItemSelection[],
  isShopifyPlus?: boolean,
) {
  const hideOldPageUrlTargeting = isHidingTheOldPageUrlTargetingSupported(
    experienceCreationVersion,
  );
  const pageType: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Page Type`,
    options: [
      { key: `/`, value: `Homepage` },
      { key: `.*/cart`, value: `Cart` },
      ...(isShopifyPlus ? [{ key: `.*/checkout`, value: `Checkout` }] : []),
      { key: `.*/collections/.*`, value: `Collection` },
      { key: `.*/products/.*`, value: `Product` },
    ],
    envKey: `Page $op "$value"`,
  };
  const isInPageEnabled = useFeatureBit(FeatureBit.IN_PAGE_PLACEMENT);

  let inPageEnvKey = `InPage("$value")`;
  let inColEnvKey = `InCol("$value")`;
  if (!isInPageEnabled) {
    inPageEnvKey = `Page $op "$value"`;
    inColEnvKey = `Page $op "$value"`;
  }
  const pageUrl: QBItemProp = {
    kind: QBType.TEXT_VALUE,
    caption: `Page Url`,
    envKey: inPageEnvKey,
    defaultTextValueOp: TextValueOps.INCLUDES,
  };

  const productOOS: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Product Out Of Stock`,
    envKey: `OOS == $value`,
    disableOrCond: true,
    options: [
      { key: `true`, value: `yes` },
      { key: `false`, value: `no` },
    ],
  };

  const productPrice: QBItemProp = {
    kind: QBType.NUMERIC_VALUE,
    caption: `Product Price`,
    envKey: `Price $op $value`,
    disableOrCond: true,
    hasCurrencyCode: true,
    lockCurrency: true,
    hideCurrencyCodeCheckbox: true,
    defaultNumericValueOp: NumberValueOps.GT,
  };
  const isVisitInPagesClientSide = useFeatureBit(
    FeatureBit.VISIT_IN_PAGE_AUDIENCE_CLIENT_SIDE,
  );

  const pageUrlWithDomain: QBItemProp = {
    kind: QBType.TEXT_VALUE,
    caption: `Page Url (WITH DOMAIN)`,
    alsoApplyOnQueryParams: isVisitInPagesClientSide,
    envKey: `UrlTarget("$value","$op",$qp)`,
    defaultTextValueOp: TextValueOps.INCLUDES,
  };

  const hasUtmPlacement = other?.find((o) =>
    o?.qbProps?.caption?.includes(`UTM`),
  );

  const utmParams: QBItemProps = [
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Content`,
      hasTimeframe: true,
      envKey: `QueryParam("utm_content") $op "$value"`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Campaign`,
      hasTimeframe: true,
      envKey: `QueryParam("utm_campaign") $op "$value"`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Term`,
      hasTimeframe: true,
      envKey: `QueryParam("utm_term") $op "$value"`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Medium`,
      hasTimeframe: true,
      envKey: `QueryParam("utm_medium") $op "$value"`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Source`,
      hasTimeframe: true,
      envKey: `QueryParam("utm_source") $op "$value"`,
    },
  ];

  const categories: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Categories`,
    options: resources.collections.map(
      (col) =>
        ({
          key: col.handle,
          value: col.title,
        } as SelectOptions),
    ),
    envKey: inColEnvKey,
  };

  const productsFromCollections: QBItemProp = {
    kind: QBType.ARRAY_VALUE,
    caption: `Products From Collections`,
    options: resources.collections.map(
      (col) =>
        ({
          key: col.handle,
          value: col.title,
        } as SelectOptions),
    ),
    envKey: `"$value" $op ProductCollections`,
  };

  const products: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Products`,
    options: removeDuplicatesByKey(
      resources.products
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        })
        .map(
          (col) =>
            ({
              key: col.handle,
              image: col?.image?.src,
              value: getProductValue(col),
            } as SelectOptions),
        ),
      `value`,
    ),
    envKey: inPageEnvKey,
  };

  const tags: QBItemProp = {
    kind: QBType.ARRAY_VALUE,
    caption: `Tag`,
    options: resources?.tags?.map(
      (tag) =>
        ({
          key: tag,
          value: tag,
        } as SelectOptions),
    ),
    envKey: `"$value" $op Tags`,
  };
  const isAttributesEnabled =
    useFeatureBit(FeatureBit.VARIANT_ATTRIBUTES) &&
    hasVariantPrimaryAttrName(resources);
  const variantMainAttribute: QBItemProp = {
    kind: QBType.ARRAY_VALUE,
    caption: `${resources.mainAttribute} (VARIANT PRIMARY ATTRIBUTE)`,
    options:
      resources?.mainAttributeOptions?.map?.(
        (tag) =>
          ({
            key: tag,
            value: tag,
          } as SelectOptions),
      ) ?? [],
    envKey: `HasVariantAttribute("${resources.mainAttribute}","$value")`,
  };
  const OOSPlacementEnabled = useFeatureBit(FeatureBit.OOS_PRICE_PLACEMENT);

  const placementProps = [
    pageType,
    ...(OOSPlacementEnabled ? [productOOS, productPrice] : []),
    categories,
    products,
    productsFromCollections,
    ...(tags?.options?.length > 0 ? [tags] : []),
    ...(isAttributesEnabled ? [variantMainAttribute] : []),
    ...(hideOldPageUrlTargeting ? [] : [pageUrl]),
    pageUrlWithDomain,
    ...(hasUtmPlacement ? utmParams : []),
  ];

  return {
    placementProps,
    categories,
    products,
    pageType,
    productsFromCollections,
    tags,
    pageUrl: hideOldPageUrlTargeting ? pageUrlWithDomain : pageUrl,
  };
}
