import styled from 'styled-components';
import React, { useRef } from 'react';
import { Flexbox } from '@/components/flex';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { StylingSection } from '@/features/editor/widgets/custom-widget/blueprint-customize';
import { LinkInput } from '@/features/editor/widgets/custom-widget/inputs/shared/link';
import { DeviceType } from '@/utils/definitions';
import { RedirectSourceUrlModal } from '@/features/editor/widgets/page-redirect/source-url-modal';
import { FormattedSourceUrl } from '@/features/editor/widgets/page-redirect/formatted-source-url';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { TitleWrapper } from '@/features/editor/widgets/changelog';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { VSpace } from '@/components/spacing';
import { TargetingControlsList } from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-list';
import { useWidgetControls } from '@/features/editor/widgets/shared/use-widget-controls';
import { CircularCheckmark } from '@/components/circular-checkmark';
import { usePageRedirect } from '@/features/editor/widgets/page-redirect/use-page-redirect';
import { StickinessMode } from '@/pkg/sdk';
import { ConfirmModal } from '@/components/confirm-modal';
import { SharedElementOverlay } from '@/components/shared-element-overlay';

export interface PageRedirectWidgetProps {
  originalChange: EditorDeclarativeBlock;
}

export function PageRedirectWidget({
  originalChange,
}: PageRedirectWidgetProps) {
  const {
    props,
    newChange,
    setIsSourceModalVisible,
    isSourceModalVisible,
    setSourceRules,
    setRetainQueryParams,
    setStickinessMode,
    setTargetUrl,
    targetUrlInitialValue,
    onNext,
    experienceControls,
  } = usePageRedirect(originalChange);
  const { sharedElement, closeAndNavigateToDashboard } = experienceControls;
  const sourceUrlRef = useRef(null);
  const isDesk = useIsPreviewingDesktop();
  const { WidgetControls, controlsProps } = useWidgetControls(onNext, `Next`);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  const setSelectedStickinessMode = (mode: StickinessMode, state: boolean) => {
    if (state) {
      setStickinessMode(mode);
    } else {
      setStickinessMode(`none`);
    }
  };

  const onConfirmClose = () => {
    closeAndNavigateToDashboard();
  };

  return (
    <Wrapper isDesk={isDesk}>
      <RedirectSourceUrlModal
        newChange={newChange}
        onSave={setSourceRules}
        fromRef={sourceUrlRef}
        isVisible={isSourceModalVisible}
        setIsVisible={setIsSourceModalVisible}
      />
      <SharedElementOverlay {...sharedElement.props} fromElement={sourceUrlRef}>
        <ConfirmModal
          neutral
          onDiscard={sharedElement.hide}
          onConfirm={onConfirmClose}
          title="You have unsaved changes"
          description="Are you sure you want to discard the changes?"
          yesCaption="Discard Changes"
          noCaption="Continue Editing"
        />
      </SharedElementOverlay>
      <InspectorWidgetLayout
        progress={75}
        title={
          <TitleWrapper>
            <EditorRenameButton />
            <DescriptionEditButton />
          </TitleWrapper>
        }
        backCaption="< back to dashboard"
        onBackClicked={() => experienceControls.onCloseEditorPageClicked()}
        footer={<WidgetControls {...controlsProps} showHistory={false} />}
      >
        <GeneralText>
          1. What audience and where will the experience run?
        </GeneralText>
        <VSpace value={2} />
        <TargetingControlsList disablePlacementPicker />
        <VSpace value={3} />
        <GeneralText>2. Which redirection do you want to setup?</GeneralText>
        <Section ref={sourceUrlRef}>
          <h3>Source URL</h3>
          <div onClick={() => setIsSourceModalVisible(true)}>
            {newChange?.pageRedirectOpts?.sourceUrlProps?.length > 0 ? (
              <DummyInput>
                <FormattedSourceUrl
                  sourceUrlProps={newChange.pageRedirectOpts?.sourceUrlProps}
                />
              </DummyInput>
            ) : (
              <LinkInput
                customizationIdx={1}
                customization={undefined}
                componentIdx={1}
                component={undefined}
                specIdx={1}
                spec={undefined}
                device={isDesk ? DeviceType.Desktop : DeviceType.Mobile}
                initialValues={() => ``}
                onValuesChanged={noop}
              />
            )}
          </div>
          <h3>Destination URL</h3>
          <LinkInput
            customizationIdx={1}
            customization={undefined}
            componentIdx={1}
            component={undefined}
            specIdx={1}
            spec={undefined}
            device={isDesk ? DeviceType.Desktop : DeviceType.Mobile}
            initialValues={targetUrlInitialValue}
            onValuesChanged={(_, value) => setTargetUrl(value)}
          />

          <h3>Source page query parameters</h3>
          <CircularCheckmark
            selected={props?.retainQueryParams}
            onChange={(value) => setRetainQueryParams(value)}
            caption="Retain query parameters"
          />

          <h3>Stickiness mode</h3>
          <div className="radio-buttons">
            <CircularCheckmark
              caption="Once per page load"
              selected={
                !props?.stickinessMode || props?.stickinessMode === `none`
              }
              disabled={
                !props?.stickinessMode || props?.stickinessMode === `none`
              }
              onChange={(sel) => setSelectedStickinessMode(`none`, sel)}
            />
            <CircularCheckmark
              caption="Once per session"
              selected={props?.stickinessMode === `session`}
              onChange={(sel) => setSelectedStickinessMode(`session`, sel)}
            />
            <CircularCheckmark
              caption="Once per user"
              selected={props?.stickinessMode === `user`}
              onChange={(sel) => setSelectedStickinessMode(`user`, sel)}
            />
          </div>

          <p>
            * Note that customers who reach the source URL will automatically be
            redirected to the destination URL, so we will override & disable the
            general page targeting of the experiment
          </p>
        </Section>
      </InspectorWidgetLayout>
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    height: 100%;
    width: ${(p: any) => (p.isDesk ? `100%` : `65%`)};

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }

    div.input-link {
      cursor: pointer;
      grid-template-columns: minmax(0, 1fr) 4rem;

      .prefix {
        display: none;
      }
    }

    div.input-link:first-child .content {
      pointer-events: none !important;
    }
  }
`;

const Section = styled(StylingSection)`
  && {
    text-align: start;
    padding: 2rem;
    gap: 1.5rem;

    && > h3 {
      padding: 0;
      margin: 0;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      color: #798794;
      font-size: 1.4rem;
    }

    && > p {
      padding: 0;
      margin: 0;
      font-family: 'Inter', sans-serif;
      color: #798794;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.8;
      letter-spacing: 0.5px;
    }

    .radio-buttons {
      display: flex;
      flex-direction: column;
      gap: 1.4rem;
    }

    .checkmark-top-wrapper {
      opacity: 1 !important;
    }

    .checkmark-wrapper {
      border-radius: 0.5rem;
    }

    .checkmark-content {
      border-radius: 0.3rem;
    }
  }
`;

const DummyInput = styled.div`
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  color: #5b656e;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03px;
  transform: translate(0, 0);
  background-color: #ffffff;
  box-shadow: 0 18px 26px 0 rgba(177, 217, 203, 0.18);
  padding: 0.8rem 1.8rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: stretch;
  white-space: nowrap;
`;
