import * as React from 'react';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { GradientModal } from '@/components/gradient-modal';
import {
  BackModalHeader,
  Footer,
} from '@/features/editor/widgets/shared/modals/commons';
import {
  PlacementModel,
  usePlacementModal,
} from '@/features/editor/widgets/shared/modals/use-placement-modal';
import { QueryBuilder } from '@/components/query-builder';
import { CardButton, MixedCardLayout } from '@/components/card-button';
import { Placement } from '@/webapi/use-experience-api';
import { PageImage } from '@/components/page-image/page-image';
import { conditional } from '@/utils/conditional';
import { ShopifyEnableCheckout } from '@/features/editor/widgets/shared/modals/shopify-checkout/shopify-enable-checkout';
import { EditorContext } from '@/features/editor/context/editor-context';
import { CheckoutType, FeatureBit } from '@/webapi/use-auth-api';
import { AccountContext, useFeatureBit } from '@/features/account-context';

export interface PlacementModalProps {
  fromRef: React.MutableRefObject<HTMLElement>;
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

export function PlacementModal({
  fromRef,
  isVisible,
  setIsVisible,
}: PlacementModalProps) {
  const {
    allPages,
    homePage,
    allCollectionsPages,
    allProductsPages,
    cartPage,
    miniCartPage,
    checkoutPage,
    postPurchasePage,
    customUrl,
    otherPage,
    toRect,
    placementProps,
    onSelection,
    onPostPurchase,
    selected,
    onSave,
    onBackToSelection,
    onQueryBuilderChange,
    isCustomTargeting,
    alias,
    specificCollections,
    productFromCollection,
    productFromTags,
    specificProducts,
    isShopifyPlus,
    isTestStore,
    hasCheckout,
    isCheckoutEnableStep,
    setIsCheckoutEnableStep: setShowLegacyCheckoutHelp,
    checkoutType,
  } = usePlacementModal(setIsVisible);

  const { isCartSelectorExist } = useContext(AccountContext);

  const isCheckoutExtensibilityEnabled = useFeatureBit(
    FeatureBit.CHECKOUT_EXTENSIBILITY,
  );

  const onCheckoutSelected = (value: boolean, placement: Placement) => {
    if (
      checkoutType === CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY &&
      isCheckoutExtensibilityEnabled
    ) {
      onCheckoutExtensibility(value, placement);
    } else {
      onLegacyCheckout(value, placement);
    }
  };

  const onCheckoutExtensibility = (value: boolean, placement: Placement) => {
    onSelection(value, placement);
  };

  const onLegacyCheckout = (value: boolean, placement: Placement) => {
    if (hasCheckout) {
      onSelection(value, placement);
    } else {
      setShowLegacyCheckoutHelp(true);
    }
  };

  const isCheckoutDisabled = useMemo(
    () => !(isShopifyPlus || isTestStore),
    [isShopifyPlus, isTestStore],
  );

  return (
    <GradientModal
      closeTop="0.5rem"
      closeRight="2.5rem"
      fromStyle={{
        borderRadius: `5rem`,
        backgroundColor: `#DEDEDE`,
        padding: `2rem 0 0 0`,
      }}
      toStyle={{
        borderRadius: `1rem`,
        backgroundColor: `#FFFFFF`,
        padding: `2rem 0 0 0`,
      }}
      isVisible={isVisible}
      showBackdrop
      targetPosAndSize={toRect}
      fromRef={fromRef}
      overflowTop={{ heightInRem: 8 }}
      overflowBottom={{ heightInRem: 8, turnPointInPercent: 30 }}
      onClose={() => setIsVisible(false)}
      header={conditional(
        `Choose where the experience should be displayed`,
        isCustomTargeting,
        <BackModalHeader
          back={onBackToSelection}
          header="Build a Custom Page Rule"
        />,
        isCheckoutEnableStep,
        <BackModalHeader
          back={onBackToSelection}
          header="Customizing Your Checkout in Just One Step Away"
        />,
      )}
      footer={conditional(
        <Footer onClick={onSave} />,
        isCheckoutEnableStep,
        <div />,
      )}
    >
      <Container>
        {conditional(
          <Grid>
            <LineItem
              key={allPages.name}
              alias={alias}
              placement={allPages}
              selected={selected}
              onSelection={onSelection}
            />
            <LineItem
              key={homePage.name}
              alias={alias}
              placement={homePage}
              selected={selected}
              onSelection={onSelection}
            />
            <LineItem
              key={allCollectionsPages.name}
              alias={alias}
              placement={allCollectionsPages}
              selected={selected}
              onSelection={onSelection}
            >
              <CustomOptionWrapper>
                <CustomOptionSelected>All Collections</CustomOptionSelected>
                <CustomOption
                  onClick={() => onSelection(false, specificCollections)}
                >
                  Specific Collections
                </CustomOption>
              </CustomOptionWrapper>
            </LineItem>
            <LineItem
              key={allProductsPages.name}
              alias={alias}
              placement={allProductsPages}
              selected={selected}
              onSelection={onSelection}
            >
              <CustomOptionWrapper>
                <CustomOptionSelected>All Products</CustomOptionSelected>
                <CustomOption
                  onClick={() => onSelection(false, productFromCollection)}
                >
                  Products from Collection
                </CustomOption>
                <CustomOption
                  onClick={() => onSelection(false, productFromTags)}
                >
                  Products with TAG
                </CustomOption>
                <CustomOption
                  onClick={() => onSelection(false, specificProducts)}
                >
                  Specific Products
                </CustomOption>
              </CustomOptionWrapper>
            </LineItem>
            <LineItem
              key={cartPage.name}
              alias={alias}
              placement={cartPage}
              selected={selected}
              onSelection={onSelection}
            />
            {isCartSelectorExist && (
              <LineItem
                key={miniCartPage.name}
                alias={alias}
                placement={miniCartPage}
                selected={selected}
                onSelection={onSelection}
              />
            )}
            <LineItem
              key={checkoutPage.name}
              alias={alias}
              disabled={isCheckoutDisabled}
              placement={checkoutPage}
              selected={selected}
              onSelection={onCheckoutSelected}
            />
            <LineItem
              key={postPurchasePage.name}
              alias={alias}
              placement={postPurchasePage}
              selected={selected}
              onSelection={onPostPurchase}
            />
            <LineItem
              key={customUrl.name}
              alias={alias}
              placement={customUrl}
              selected={selected}
              onSelection={onSelection}
            />
            <LineItem
              key={otherPage.name}
              alias={alias}
              placement={otherPage}
              selected={selected}
              onSelection={onSelection}
            />
          </Grid>,

          isCustomTargeting,
          <>
            <span>
              Use our query builder to tailor the target audience to the
              experience
            </span>
            <QueryBuilder
              onChange={onQueryBuilderChange}
              initialProps={placementProps}
              initialState={selected.other || []}
            />
          </>,

          isCheckoutEnableStep,
          <ShopifyEnableCheckout />,
        )}
      </Container>
    </GradientModal>
  );
}

const LineItem = ({
  alias,
  placement,
  selected,
  onSelection,
  children,
  disabled,
}: {
  alias: string;
  placement: PlacementModel;
  selected: Placement;
  onSelection: (value: boolean, placement: Placement) => void;
  children?: any;
  disabled?: boolean;
}) => {
  const {
    experienceState: {
      currentExperience: {
        targeting: { device },
      },
    },
  } = useContext(EditorContext);
  const isSelected = selected.kind === placement.value.kind;
  return (
    <CardButton
      key={placement.name}
      isDisabled={disabled}
      selected={isSelected}
      height="13rem"
      borderRadius="1.5rem"
      canBeSelected
      padding="0.9rem 1rem"
      onClicked={(_, value) => onSelection(value, placement.value)}
    >
      <MixedCardLayout
        verticalGap="1rem"
        horizontalDistribution="6rem 1fr"
        padding="0 1.5rem"
        primaryColor={isSelected ? `#003CED` : `#576470`}
        accentColor={isSelected ? `#003CED` : `#758390`}
        primaryFontSize="1.4rem"
        accentFontSize="1.1rem"
        accentFontFamily="Inter"
        accentFontWeight="500"
        content={
          <PageImage alias={alias} page={placement.image} device={device} />
        }
      >
        <span>{placement?.name}</span>
        <span style={{ lineHeight: `1.9rem` }}>
          {isSelected
            ? children || placement?.description
            : placement?.description}
        </span>
      </MixedCardLayout>
    </CardButton>
  );
};

const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 2.5rem;
`;

const CustomOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    width: fit-content;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;

const CustomOptionSelected = styled.span`
  background: #2860f6;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
`;

const CustomOption = styled.span`
  color: #758390 !important;
  cursor: grab;

  background: #eeeeee;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  font-weight: 500;
  font-size: 1.2rem;

  transition: color 500ms ease-out, opacity 200ms ease-out;

  :hover {
    color: #30373d !important;
    opacity: 0.8;
  }

  :active {
    cursor: grabbing;
    opacity: 0.6;
    color: #003ced !important;
  }
`;
