import React, { MutableRefObject, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useQueryParam } from 'use-query-params';
import { useAudiencesLoader } from '@/features/editor/use-editor-loader';
import { maybe } from '@/features/details/utils';
import { useAudienceQueryBuilder } from '@/features/editor/widgets/shared/modals/use-audience-query-builder';
import { QBItemProp, QBItemSelection } from '@/components/query-builder/models';
import { useAudiencesApi } from '@/features/dashboard/audiences/api';
import { Audience, AudienceType } from '@/features/dashboard/audiences/models';
import {
  Placement,
  PlacementKind,
  Targeting,
} from '@/webapi/use-experience-api';
import { centered, useSharedElement } from '@/components/use-shared-element';
import { SharedElementOverlayProps } from '@/components/shared-element-overlay';
import {
  DateRangeParam,
  EndDateParameter,
  StartDateParameter,
} from '@/features/dashboard/shared/date-picker/date-picker';
import { DateRange } from '@/features/dashboard/reports/models';
import { calcStartAndEndDates } from '@/features/dashboard/audiences/dateUtil';

export interface AudiencesContext {
  loading: boolean;
  onSave: () => void;
  audienceProps: QBItemProp[];
  audience: QBItemSelection[];
  onQueryBuilderChange: (v: QBItemSelection[]) => void;
  nameStep: boolean;
  setNameStep: (b: boolean) => void;
  name: string;
  setName: (s: string) => void;
  description: string;
  setDescription: (s: string) => void;
  isModalVisible: boolean;
  onDelete: (audience: Audience) => void;
  setIsModalVisible: (b: boolean) => void;
  audiencesList: Array<Audience>;
  sharedOverlayProps: SharedElementOverlayProps;
  audienceToEdit: Audience | undefined;
  showConfirmModal: () => void;
  hideConfirmModal: () => void;
  confirmModalRef: MutableRefObject<any>;
  audienceDelete: () => Promise<void>;
  setAudienceToDelete: (a: Audience) => void;
  onEdit: (audience: Audience) => void;
}

export const AudiencesContext = React.createContext({} as AudiencesContext);

export function newAudiencesCtx(): AudiencesContext {
  const { resources, loading: loaderLoading } = useAudiencesLoader();
  const props = maybe(() => useAudienceQueryBuilder(resources));
  const [audience, setAudience] = useState<QBItemSelection[]>([]);

  const [nameStep, setNameStep] = useState(false);
  const [name, setName] = useState(``);
  const [description, setDescription] = useState(``);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [audiencesList, setAudiencesList] = useState<Array<Audience>>([]);
  const [audienceToDelete, setAudienceToDelete] = useState<Audience>(null);
  const [audienceToEdit, setAudienceToEdit] = useState<Audience>(null);
  const [dateRange] = useQueryParam<DateRange>(`rn`, DateRangeParam);
  const [startDate] = useQueryParam(`s`, StartDateParameter);
  const [endDate] = useQueryParam(`e`, EndDateParameter);

  const {
    loading,
    createAudience,
    listAudiences,
    deleteAudience,
    updateAudience,
  } = useAudiencesApi(true);

  const initAudiences = async (
    dateRange?: DateRange,
    startDate?: Date | undefined,
    endDate?: Date | undefined,
  ) => {
    const { start, end } = calcStartAndEndDates(startDate, endDate, dateRange);

    const audiences = await listAudiences(start, end);
    if (audiences?.audiences?.length > 0) {
      setAudiencesList(audiences.audiences);
    } else {
      setAudiencesList([]);
    }
  };

  useAsyncEffect(async () => {
    await initAudiences(dateRange, startDate, endDate);
  }, [dateRange, startDate, endDate]);

  const onSave = async () => {
    if (!nameStep) {
      setNameStep(true);
      if (audienceToEdit?.connectedExperiences) {
        setName(``);
        setDescription(``);
      }
      return;
    }
    if (audienceToEdit && !audienceToEdit?.connectedExperiences) {
      const toUpdate = makeAudienceObject(name, description, audience);
      toUpdate.id = audienceToEdit.id;
      toUpdate.experienceId = audienceToEdit.experienceId;
      await updateAudience(toUpdate);
      setAudienceToEdit(null);
    } else {
      await createAudience(makeAudienceObject(name, description, audience));
    }
    setIsModalVisible(false);
    setName(``);
    setDescription(``);
    setNameStep(false);
    setAudience([]);
    await initAudiences(dateRange, startDate, endDate);
  };

  const onEdit = (audience: Audience) => {
    setDescription(audience.description);
    setName(audience.name);
    setAudience(audience?.targeting?.other);
    setAudienceToEdit(audience);
    setNameStep(false);
    setIsModalVisible(true);
  };

  const onDelete = (audience: Audience) => {
    showConfirmModal();
    setAudienceToDelete(audience);
  };

  const onQueryBuilderChange = (value: QBItemSelection[]) => {
    setAudience(value);
  };

  const {
    confirmModalRef,
    sharedOverlayProps,
    showConfirmModal,
    hideConfirmModal,
  } = confirmModalProps();

  const audienceDelete = async () => {
    await deleteAudience(audienceToDelete);
    setAudienceToDelete(null);
    hideConfirmModal();
    await initAudiences(dateRange, startDate, endDate);
  };

  const hideModal = (b: boolean) => {
    if (!b) {
      setDescription(``);
      setName(``);
      setAudience([]);
      setAudienceToEdit(null);
      setNameStep(false);
    }
    setIsModalVisible(b);
  };
  return {
    onSave,
    onQueryBuilderChange,
    loading: loaderLoading || loading,
    audienceProps: props?.audienceProps || [],
    audience,
    nameStep,
    setNameStep,
    name,
    setName,
    description,
    setDescription,
    isModalVisible,
    onDelete,
    setIsModalVisible: hideModal,
    audiencesList,
    sharedOverlayProps,
    audienceToEdit,
    showConfirmModal: () => {
      setAudienceToDelete(null);
      showConfirmModal();
    },
    hideConfirmModal: () => {
      setAudienceToDelete(null);
      hideConfirmModal();
    },
    confirmModalRef,
    audienceDelete,
    setAudienceToDelete,
    onEdit,
  };
}

function confirmModalProps() {
  const confirmModalRef = useRef(null);

  const {
    props: sharedOverlayProps,
    show: showConfirmModal,
    hide: hideConfirmModal,
  } = useSharedElement(
    {
      showBackdrop: true,
      extraFrom: {
        background: `#a6afb8`,
        opacity: `0`,
      },
      extraTo: {
        background: `white`,
        opacity: `1`,
      },
    },
    confirmModalRef,
    () => centered(19, 42),
  );
  return {
    confirmModalRef,
    sharedOverlayProps,
    showConfirmModal,
    hideConfirmModal,
  };
}

export function makeAudienceObject(
  name: string,
  description: string,
  audience: QBItemSelection[],
) {
  return {
    name,
    description,
    type: AudienceType.CUSTOM_AUDIENCE_TYPE,
    targeting: {
      segments: [],
      placement: {
        kind: PlacementKind.ALL_PAGES,
      } as Placement,
      other: audience,
    } as Targeting,
  } as Audience;
}
