import React, { useRef } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import Portal from '@/components/portal';

export interface VideoPlayerOverlayProps {
  onBackdropClicked?: () => void;
  url?: string;
}

export function VideoPlayerOverlay({
  onBackdropClicked,
  url,
}: VideoPlayerOverlayProps) {
  const ref = useRef<HTMLIFrameElement | null>(null);

  const onWrapperClick = (ev) => {
    ev.stopPropagation();
  };

  return (
    <Portal selector="body">
      <Backdrop onClick={onBackdropClicked}>
        <IoMdClose className="close" onClick={onBackdropClicked} />
        <Wrapper onClick={onWrapperClick}>
          <iframe
            ref={ref}
            id="demo-video-iframe"
            title="Visually Product Demo"
            src={url}
            loading="lazy"
            allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          />
        </Wrapper>
      </Backdrop>
    </Portal>
  );
}

const Backdrop = styled.div`
  height: 100vh;
  width: 100vw;
  inset: 0;
  position: fixed;
  display: flex;
  background-color: rgba(200, 200, 200, 0.4);
  backdrop-filter: blur(14px);
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
  cursor: pointer;

  .close {
    position: absolute;
    top: 2.5rem;
    right: 3.5rem;
    font-size: 3.5rem;
    color: rgb(105, 117, 126);
    transition: transform 0.1s linear, color 0.1s linear;
  }

  .close:hover {
    color: black;
    transform: scale(1.1);
  }

  .close:active {
    transform: scale(0.9);
  }
`;

const Wrapper = styled.div`
  padding: 5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  align-content: center;
  justify-items: center;
  justify-content: center;

  iframe {
    background: black;
    height: 56.5vh;
    width: 70vw;
    appearance: none;
    border: none;
    outline: none;
    box-shadow: none;
    background-size: cover;
    border-radius: 3rem;
    overflow: hidden;
  }
`;
