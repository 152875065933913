import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { CardWrapper } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { Caption } from '../../shared/shared-styles';
import { Flexbox } from '@/components/flex';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { HSpace } from '@/components/spacing';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { maybe } from '@/features/details/utils';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';

const HEIGHT_KEY = `height`;
const WIDTH_KEY = `width`;
const LOCK_RATIO_KEY = `lockRatio`;
const HEIGHT_UNIT_KEY = `heightUnit`;
const WIDTH_UNIT_KEY = `widthUnit`;

export function SizeInput({
  customization,
  initialValues,
  onValuesChanged,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);
  const { currentSchema } = useContext(CustomWidgetContext);
  const getHeightUnit = useCallback(
    () => initialValues(HEIGHT_UNIT_KEY),
    [undoRedoCount],
  );

  const getWidthUnit = useCallback(
    () => initialValues(WIDTH_UNIT_KEY),
    [undoRedoCount],
  );

  const getHeight = useCallback(
    () => initialValues(HEIGHT_KEY),
    [undoRedoCount],
  );

  const getWidth = useCallback(() => initialValues(WIDTH_KEY), [undoRedoCount]);

  const getLockRatio = useCallback(
    () => initialValues(LOCK_RATIO_KEY),
    [undoRedoCount],
  );

  const initHeight = getHeight();
  const initWidth = getWidth();

  const [height, setHeight] = useState(initHeight);
  const [width, setWidth] = useState(initWidth);
  const [isRatioLocked, setIsRatioLocked] = useState(getLockRatio());

  function calcRatio() {
    return parseDim(width, getWidthUnit()) / parseDim(height, getHeightUnit());
  }

  const [ratio, setRatio] = useState(calcRatio());
  const [finalHeight, setFinalHeight] = useState(initHeight);
  const [finalWidth, setFinalWidth] = useState(initWidth);

  useEffect(() => {
    const ratio = calcRatio();
    setRatio(ratio);
  }, [isRatioLocked]);

  useEffect(() => {
    setFinalWidth(width);
    if (isRatioLocked) {
      setTimeout(() => {
        const w = parseDim(width, getWidthUnit());
        const h = Math.round(w / ratio);
        setFinalHeight(`${h}${getHeightUnit()}`);
      }, 50);
    }
  }, [width]);

  useEffect(() => {
    setFinalHeight(height);
    if (isRatioLocked) {
      setTimeout(() => {
        const h = parseDim(height, getHeightUnit());
        const w = Math.round(h * ratio);
        setFinalWidth(`${w}${getWidthUnit()}`);
      }, 50);
    }
  }, [height]);

  useEffect(() => {
    onValuesChanged(WIDTH_KEY, finalWidth);
  }, [finalWidth]);

  useEffect(() => {
    onValuesChanged(HEIGHT_KEY, finalHeight);
  }, [finalHeight]);

  useEffect(() => {
    onValuesChanged(LOCK_RATIO_KEY, isRatioLocked);
  }, [isRatioLocked]);

  useEffect(() => {
    setHeight(getHeight());
    setWidth(getWidth());
    setRatio(calcRatio());
    setIsRatioLocked(getLockRatio());
  }, [undoRedoCount]);

  const lockedWidth = useMemo(
    () => isLocked(`width`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );
  const lockedHeight = useMemo(
    () => isLocked(`height`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );

  function isLocked(input: string): boolean {
    // For ADD TO CART below product (Upsells)
    if (customization.key === `atcButton`) {
      const addCartPlacement = maybe(
        () =>
          currentSchema?.settings?.general?.specs?.find(
            (e) => e?.key === `addCartPlacement`,
          )?.value?.value ?? null,
      );

      const isPlacementProduct = addCartPlacement === `product`;
      const isInputWidth = input === `width`;

      if (isPlacementProduct && isInputWidth) {
        return true;
      }
    }
    return false;
  }

  return (
    <CardWrapper title={<Caption>Size</Caption>}>
      <Wrapper>
        <Checkboard>
          <SizeBoxHint>
            <ArrowsIcon />
          </SizeBoxHint>
        </Checkboard>
        <FillDiv>
          <ControlsGrid>
            <Flexbox
              direction="row"
              width="100%"
              align="center"
              style={{
                opacity: width.includes(`NaN`) ? 0.5 : 1,
                pointerEvents: width.includes(`NaN`) ? `none` : `auto`,
              }}
            >
              <Caption>Width</Caption>
              <HSpace />
              <NumberInput
                locked={lockedWidth}
                minimal
                min={1}
                max={9999}
                suffix={getWidthUnit()}
                defaultValue={finalWidth}
                onChange={(val) => setWidth(val)}
              />
            </Flexbox>
            <Flexbox
              direction="row"
              width="100%"
              align="center"
              style={{
                opacity: height.includes(`NaN`) ? 0.5 : 1,
                pointerEvents: height.includes(`NaN`) ? `none` : `auto`,
              }}
            >
              <Caption>Height</Caption>
              <HSpace />
              <NumberInput
                locked={lockedHeight}
                minimal
                min={1}
                max={9999}
                suffix={getHeightUnit()}
                defaultValue={finalHeight}
                onChange={(val) => setHeight(val)}
              />
            </Flexbox>
            <CheckboxInput
              defaultValue={isRatioLocked}
              onChange={(val) => setIsRatioLocked(val)}
            >
              Lock aspect ratio
            </CheckboxInput>
          </ControlsGrid>
        </FillDiv>
      </Wrapper>
    </CardWrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 7fr;
  align-items: center;
  justify-content: center;
  grid-gap: 3rem;
`;

const Checkboard = styled.div`
  height: 100%;
  width: 100%;
  background: #f2f6f9
    conic-gradient(from 90deg at 1px 1px, #0000 90deg, #ece9e9 0) 0 0/2rem 2rem;
  background-position: 0.7rem 1rem;
  border-radius: 10px;
  padding: 2rem;
`;

const SizeBoxHint = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #d3d9dd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11), 0 7px 8px 0 rgba(0, 0, 0, 0.12);

  padding: 0.5rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FillDiv = styled.div`
  width: 100%;
`;

const ControlsGrid = styled.div`
  display: grid;
  grid-template-areas: 'width height' 'lock lock';
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;

  padding: 1rem 0;

  && > :nth-child(1) {
    grid-area: width;
  }

  && > :nth-child(2) {
    grid-area: height;
  }

  && > :nth-child(3) {
    grid-area: lock;
  }
`;

const ArrowsIcon = () => (
  <StaticImage
    src="../../../../../../assets/size_arrows.png"
    alt="edit"
    placeholder="none"
    loading="eager"
    height={50}
    objectFit="contain"
  />
);

export const parseDim = (str: string, unit: string): number =>
  Math.round(parseFloat(str.replaceAll(unit, ``)));
