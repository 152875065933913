import * as React from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { GoChevronDown } from 'react-icons/go';
import ReactTooltip from 'react-tooltip';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TargetingDeviceType } from '@/webapi/use-experience-api';
import { DeviceType } from '@/utils/definitions';
import { SharedElementOverlay } from '@/components/shared-element-overlay';
import { NavigationOverlay } from '@/features/editor/preview-navigation/navigation-overlay';
import { PageKind } from '@/features/editor/context/use-device-navigation';
import { HSpace } from '@/components/spacing';
import { useThemesModal } from '@/features/editor/widgets/shared/targeting-controls/use-themes-modal';
import { ThemesModal } from '@/features/editor/widgets/shared/modals/audience/themes-modal';
import { SelectorTooltipWrapper } from '@/features/editor/widgets/changelog/tile';

const PAGE_TOOLTIP_ID = `page-title-tooltip`;
const THEME_TOOLTIP_ID = `theme-title-tooltip`;
export const DEVICE_NAV_ID = `vsly-device-navigation`;

export function PreviewNavigation() {
  const {
    themes,
    isVisible: isThemesModalVisible,
    setIsVisible: setIsThemesModalVisible,
    onClick: onShowThemesModal,
    activeTheme,
    enabledOnMultipleThemes,
  } = useThemesModal();

  const {
    deviceNavigation: { urlToStorePage, previewUrl },
    devicePreview: {
      setPreviewDevice,
      editorState: { device: previewDevice },
    },
    experienceState: {
      currentExperience: { targeting },
    },
  } = useContext(EditorContext);

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const fromRef = useRef(null);
  const toRef = useRef(null);

  const currentPage = useMemo(() => urlToStorePage(previewUrl), [previewUrl]);

  const showMobile = useMemo(
    () =>
      [
        TargetingDeviceType.MOBILE,
        TargetingDeviceType.ALL_DEVICES,
        undefined,
      ].includes(targeting.device),
    [targeting?.device],
  );

  const showDesktop = useMemo(
    () =>
      [
        TargetingDeviceType.DESKTOP,
        TargetingDeviceType.ALL_DEVICES,
        undefined,
      ].includes(targeting.device),
    [targeting?.device],
  );

  useEffect(() => {
    if (
      previewDevice === DeviceType.Desktop &&
      targeting?.device === TargetingDeviceType.MOBILE
    ) {
      setPreviewDevice(DeviceType.Mobile);
    }

    if (
      previewDevice === DeviceType.Mobile &&
      targeting?.device === TargetingDeviceType.DESKTOP
    ) {
      setPreviewDevice(DeviceType.Desktop);
    }
  }, [previewDevice, targeting?.device]);

  const selectedDeviceLeft = useMemo(() => {
    if (
      !targeting?.device ||
      targeting?.device === TargetingDeviceType.ALL_DEVICES
    ) {
      if (previewDevice === DeviceType.Mobile) return `48%`;
      return `-8px`;
    }
    return `-8px`;
  }, [targeting?.device, previewDevice]);

  const pageTitle = useMemo(() => {
    if ([PageKind.COLLECTION, PageKind.PRODUCT].includes(currentPage.kind)) {
      return `${currentPage.kind.toString()}: ${currentPage.caption
        ?.replace(` (Product)`, ``)
        ?.replace(` (Collection)`, ``)}`;
    }
    if (PageKind.MINI_CART === currentPage.kind) {
      return `CART DRAWER`;
    }
    return currentPage.kind.toString();
  }, [currentPage]);

  const themeTitle = useMemo(() => {
    if (activeTheme === `main`) {
      return `Theme: MAIN`;
    }
    return `Theme: ${activeTheme}`;
  }, [activeTheme]);

  const shouldShowTitleTooltip = useMemo(
    () => pageTitle?.length > 20,
    [pageTitle],
  );

  const shouldShowThemeTooltip = useMemo(
    () => themeTitle?.length > 20,
    [themeTitle],
  );

  return (
    <>
      <SelectorTooltipWrapper />
      <RelativePopup ref={toRef} />
      {shouldShowTitleTooltip && (
        <Tooltip id={PAGE_TOOLTIP_ID} text={pageTitle} />
      )}
      {shouldShowThemeTooltip && (
        <Tooltip id={THEME_TOOLTIP_ID} text={themeTitle} />
      )}
      <Wrapper id={DEVICE_NAV_ID} ref={fromRef}>
        <ThemesModal
          fromRef={fromRef}
          isVisible={isThemesModalVisible}
          setIsVisible={setIsThemesModalVisible}
          themes={themes}
        />
        <SharedElementOverlay
          isVisible={isOverlayVisible}
          showBackdrop
          onBackdropClick={() => setIsOverlayVisible(false)}
          fromElement={fromRef}
          toPosition={toRef}
          widthMultiplier={1.2}
          extraFrom={{
            backgroundColor: `rgba(255,255,255,0)`,
            boxShadow: `0 12px 0 84px rgba(0,0,0,0)`,
            borderRadius: `2rem`,
            opacity: 0.8,
          }}
          extraTo={{
            backgroundColor: `rgba(255,255,255,1)`,
            boxShadow: `0 12px 0 84px rgba(0,0,0,0.06)`,
            borderRadius: `1rem`,
            opacity: 1,
          }}
        >
          <NavigationOverlay close={() => setIsOverlayVisible(false)} />
        </SharedElementOverlay>
        {showDesktop && showMobile && <DeviceTint />}
        <Devices>
          <SelectedIcon style={{ left: selectedDeviceLeft }} />
          {showDesktop && (
            <DesktopIcon
              selected={previewDevice === DeviceType.Desktop}
              onClick={() => {
                setPreviewDevice(DeviceType.Desktop);
              }}
            />
          )}
          {showMobile && (
            <MobileIcon
              selected={previewDevice === DeviceType.Mobile}
              onClick={() => {
                setPreviewDevice(DeviceType.Mobile);
              }}
            />
          )}
        </Devices>

        <PageInfo>
          <Title
            data-tip
            data-for={PAGE_TOOLTIP_ID}
            isMobile={
              previewDevice === DeviceType.Mobile && enabledOnMultipleThemes
            }
            onClick={() => {
              setIsOverlayVisible(true);
            }}
          >
            {pageTitle}
          </Title>
          <GoChevronDown
            onClick={() => {
              setIsOverlayVisible(true);
            }}
          />
          {enabledOnMultipleThemes && (
            <>
              <HSpace value={0.1} />
              <div
                style={{
                  height: `3rem`,
                  width: `1px`,
                  background: `rgba(146, 159, 171, 0.3)`,
                }}
              />
              <HSpace value={0.1} />
              <Title
                data-tip
                data-for={THEME_TOOLTIP_ID}
                isMobile={previewDevice === DeviceType.Mobile}
                onClick={onShowThemesModal}
              >
                {themeTitle}
              </Title>
              <HSpace value={0.1} />
              <ThemesIcon onClick={onShowThemesModal} />
            </>
          )}
        </PageInfo>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  background: rgba(52, 69, 134, 0.06);
  padding: 0 1.5rem 0 2rem;
  border-radius: 2rem;
  margin: -1.5rem 0 1.5rem 0;

  color: rgb(124, 138, 151);
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'JetBrains Mono', serif;
  text-transform: uppercase;

  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.7rem;
  justify-content: center;
  align-items: center;

  transition: opacity 0.3s ease-in-out;
`;

const DeviceTint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 6.8rem;
  height: 100%;
  border-radius: 2rem;
  background: rgb(0, 0, 0, 0.05);
  pointer-events: none;
`;

const Devices = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-left: -1.2rem;
`;

const PageInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const RelativePopup = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: calc(50% - 45rem / 2);
  margin: 0 auto;
  height: 50rem;
  min-width: 45rem;
  max-width: 45rem;

  opacity: 0;
`;

const Title = styled.span`
  max-width: ${(p) => (p.isMobile ? `16rem` : `19rem`)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  padding: 0.8rem 0;
`;

const SelectedIcon = styled.span`
  position: absolute;
  background: white;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.15),
    0 12px 14px 0 rgba(151, 210, 206, 0.22), 0 4px 14px 0 rgba(0, 0, 0, 0);
  top: -0.9rem;
`;

const Icon = styled.div`
  z-index: 1;
  cursor: pointer;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DesktopIcon = ({ onClick, selected }) => (
  <Icon onClick={onClick}>
    <svg width="18px" height="18px" viewBox="0 0 46 45">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="130---CREATOS---womens---21----ACTIONS"
          transform="translate(-2697.000000, -281.000000)"
        >
          <g id="Group-10" transform="translate(2697.310811, 281.562500)">
            <rect
              id="Rectangle-Copy"
              fill={selected ? `rgb(14,133,255)` : `rgb(179,191,207)`}
              x="11.2818533"
              y="41.25"
              width="23.503861"
              height="2.8125"
              rx="1.40625"
            />
            <path
              d="M17.8800338,34.6835183 L28.1875338,34.6835183 C28.7066814,34.6835183 29.1275338,35.1043707 29.1275338,35.6235183 L29.1275338,36.5639817 C29.1275338,37.0831293 28.7066814,37.5039817 28.1875338,37.5039817 L17.8800338,37.5039817 C17.3608861,37.5039817 16.9400338,37.0831293 16.9400338,36.5639817 L16.9400338,35.6235183 C16.9400338,35.1043707 17.3608861,34.6835183 17.8800338,34.6835183 Z"
              id="Rectangle-Copy-5"
              fill={selected ? `rgb(14,133,255)` : `rgb(179,191,207)`}
              transform="translate(23.033784, 36.093750) rotate(-270.000000) translate(-23.033784, -36.093750) "
            />
            <rect
              id="Rectangle"
              stroke={selected ? `rgb(14,133,255)` : `rgb(179,191,207)`}
              strokeWidth="2.82"
              x="1.41"
              y="1.41"
              width="42.3074131"
              height="29.055"
              rx="5.64"
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
);

const MobileIcon = ({ onClick, selected }) => (
  <Icon onClick={onClick}>
    <svg width="18px" height="18px" viewBox="0 0 31 44">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="130---CREATOS---womens---21----ACTIONS"
          transform="translate(-2800.000000, -281.000000)"
        >
          <g id="Group-14" transform="translate(2800.727799, 281.562500)">
            <rect
              id="Rectangle"
              stroke={selected ? `rgb(14,133,255)` : `rgb(179,191,207)`}
              strokeWidth="2.82"
              x="1.41"
              y="1.41"
              width="27.2649421"
              height="39.7954891"
              rx="5.64"
            />
            <rect
              id="Rectangle"
              fill={selected ? `rgb(14,133,255)` : `rgb(179,191,207)`}
              x="11.2818533"
              y="31.875"
              width="8.46138996"
              height="2.8125"
              rx="1.40625"
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
);

const ThemesIcon = ({ onClick }) => (
  <Icon style={{ opacity: 0.3 }} onClick={onClick}>
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" fill="none" width="24" height="24" />

      <g>
        <path
          fill="#929fab"
          d="M4 6c-1.105 0-2 .895-2 2v12c0 1.1.9 2 2 2h12c1.105 0 2-.895 2-2H4V6zm16-4H8c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2V4c0-1.105-.895-2-2-2zm-5 14H8V9h7v7zm5 0h-3V9h3v7zm0-9H8V4h12v3z"
        />
      </g>
    </svg>
  </Icon>
);

const Tooltip = ({ id, text }) => (
  <ReactTooltip
    id={id}
    place="bottom"
    backgroundColor="#fff"
    textColor="#000"
    className="selector-tooltip"
  >
    <span>{text}</span>
  </ReactTooltip>
);
