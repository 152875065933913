import {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Collection, Product } from '@/webapi/use-catalog-api';
import { Experience, PlacementKind } from '@/webapi/use-experience-api';
import {
  AccountContext,
  AccountDetails,
  useFeatureBit,
} from '@/features/account-context';
import { routes } from '@/webapi/routes';
import { DeviceType } from '@/utils/definitions';
import { CheckoutType, FeatureBit } from '@/webapi/use-auth-api';
import { urlNoPreviewThemeId } from '@/utils/url';
import { PREVIEW_THEME_ID } from '@/utils/types';
import { appendQueryParam } from '@/features/editor/widgets/changelog/tile';
import { errorPage } from '@/features/editor/context/error-page';
import { useDetachedState } from '@/components/hooks/use-detached-state';

export interface UseDeviceNavigationHook {
  previewUrl: string;
  encodedUrl: string;
  encodedUrlRef: MutableRefObject<string>;
  iframeSrcDoc: string | undefined;
  navigateTo: (url: string) => void;
  getRelatedUrls: () => [StorePage[], StorePage[]];
  urlToStorePage: (url: string) => StorePage;
  generateCheckoutUrlForPreview: () => string;
  reloadWithQueryParam: (key: string, value: string) => void;
  navigateToErrorPage: () => void;
}

export const MINI_CART_TAG = `vsly-mini-cart`;

export function useDeviceNavigation(
  collections: Collection[],
  products: Product[],
  currentExperience: Experience,
  setQuickPreviewInfo: (info: {
    url?: string;
    placement?: PlacementKind;
    device?: DeviceType;
  }) => void,
  setPreviewLoading: (boolean) => void,
  loadedApps: MutableRefObject<Array<string>>,
  editorDevice: DeviceType,
): UseDeviceNavigationHook {
  const { account, checkoutType } = useContext(AccountContext);

  const isCheckoutEnabled = useFeatureBit(FeatureBit.CHECKOUT_EXTENSIBILITY);
  const checkoutKind = useMemo(() => {
    if (
      checkoutType === CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY &&
      isCheckoutEnabled
    ) {
      return CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY;
    }
    return CheckoutType.SHOPIFY_LEGACY_CHECKOUT;
  }, [isCheckoutEnabled, checkoutType]);

  const allUrls = useMemo(
    () => buildCatalogUrls(account, collections, products, checkoutKind),
    [],
  );
  const collectionsUrls = useMemo(
    () => allUrls.filter((page) => page.kind === PageKind.COLLECTION),
    [],
  );
  const productsUrls = useMemo(
    () => allUrls.filter((page) => page.kind === PageKind.PRODUCT),
    [],
  );

  const homePage = useMemo(
    () => allUrls.filter((url) => url.kind === PageKind.HOMEPAGE)[0],
    [],
  );

  const cartPage = useMemo(
    () => allUrls.filter((url) => url.kind === PageKind.CART)[0],
    [],
  );

  const allCartPages = useMemo(
    () => allUrls.filter((url) => url.kind === PageKind.CART),
    [],
  );

  const checkoutPage = useMemo(
    () => allUrls.filter((url) => url.kind === PageKind.CHECKOUT)[0],
    [],
  );
  const miniCartPage = useMemo(
    () => allUrls.filter((url) => url.kind === PageKind.MINI_CART)[0],
    [],
  );
  const productUrlStopWords = (page: StorePage) =>
    [
      `packaging`,
      `insurance`,
      `מתנה`,
      `אריזה`,
      `shipping`,
      `protection`,
      `the-blue-cocoon-10ml`,
    ]
      .map((sw) => page.url.includes(sw) || page.caption.includes(sw))
      .find((res) => res === true);

  const topProducts = (limit: number) =>
    productsUrls
      .filter((p) => !productUrlStopWords(p))
      .sort((a, b) => a.rank - b.rank)
      .slice(0, limit);

  const topCollections = (limit: number) =>
    collectionsUrls.sort((a, b) => a.rank - b.rank).slice(0, limit);

  const placementToUrl = (): string => {
    if (
      currentExperience?.targeting?.placement?.kind ===
      PlacementKind.ALL_CATEGORIES
    ) {
      return topCollections(1)[0].url;
    }

    if (
      currentExperience?.targeting?.placement?.kind ===
      PlacementKind.ALL_PRODUCTS
    ) {
      return topProducts(1)[0].url;
    }

    if (currentExperience?.targeting?.placement?.kind === PlacementKind.CART) {
      return cartPage.url;
    }

    if (
      currentExperience?.targeting?.placement?.kind === PlacementKind.CHECKOUT
    ) {
      return checkoutPage.url;
    }

    return homePage.url;
  };

  const urlToStorePage = (url: string): StorePage => {
    const match = allUrls.filter(
      (item) => urlNoPreviewThemeId(item.url) === urlNoPreviewThemeId(url),
    );
    if (match.length === 0) {
      return {
        url: urlNoPreviewThemeId(url),
        caption: `Custom Page`,
        kind: PageKind.CUSTOM,
        rank: 0,
      };
    }
    return match?.[0];
  };

  const defaultUrl = placementToUrl();

  const [previewUrl, setPreviewUrl] = useState(
    getDefaultPreviewUrl(currentExperience, defaultUrl),
  );

  const [encodedUrl, setEncodedUrl, encodedUrlRef] = useDetachedState(
    routes.quickPreview(previewUrl),
  );
  const [iframeSrcDoc, setIframeSrcDoc] = useState<string | undefined>(
    undefined,
  );

  const [lastPlacement, setLastPlacement] = useState(
    currentExperience?.targeting?.placement,
  );

  const getRelatedUrls = (): [StorePage[], StorePage[]] => {
    if (
      currentExperience?.targeting?.placement?.kind ===
      PlacementKind.ALL_CATEGORIES
    ) {
      return [collectionsUrls, topCollections(100)];
    }
    if (
      currentExperience?.targeting?.placement?.kind ===
      PlacementKind.ALL_PRODUCTS
    ) {
      return [productsUrls, topProducts(100)];
    }
    if (currentExperience?.targeting?.placement?.kind === PlacementKind.CART) {
      return [allCartPages, allCartPages];
    }
    if (
      currentExperience?.targeting?.placement?.kind === PlacementKind.CHECKOUT
    ) {
      return [[checkoutPage], [checkoutPage]];
    }
    if (
      currentExperience?.targeting?.placement?.kind ===
        PlacementKind.ALL_PAGES ||
      currentExperience?.targeting?.placement?.kind === PlacementKind.OTHER
    ) {
      return [allUrls, [...topCollections(10), ...topProducts(90)]];
    }
    if (
      currentExperience?.targeting?.placement?.kind === PlacementKind.MINI_CART
    ) {
      return [
        allUrls,
        [miniCartPage, ...topCollections(10), ...topProducts(90)],
      ];
    }

    return [[homePage], [homePage]];
  };

  const isSpaIntegration = useFeatureBit(FeatureBit.SPA_INTEGRATION);
  const isThemesEnabled =
    useFeatureBit(FeatureBit.SHOPIFY_THEMES) && !isSpaIntegration;
  const updateLocalStorage = (url: string) => {
    const visitedPagesStr = window.localStorage.getItem(
      `vsly_visited_alias_pages`,
    );
    let visitedPages = {};
    if (visitedPagesStr) visitedPages = JSON.parse(visitedPagesStr);
    if (Object.prototype.hasOwnProperty.call(visitedPages, url)) {
      visitedPages[url] += 1;
    } else {
      visitedPages[url] = 1;
    }
    window.localStorage.setItem(
      `vsly_visited_alias_pages`,
      JSON.stringify(visitedPages),
    );
  };
  const navigateTo = (url: string) => {
    if (isThemesEnabled) {
      url = appendThemeId(url, currentExperience);
    }
    if (url) {
      const cleanUrl = urlNoPreviewThemeId(url);
      const convertedUrl = cleanUrl.endsWith(`/`)
        ? cleanUrl.slice(0, -1)
        : cleanUrl;
      if (previewUrl !== url && previewUrl !== convertedUrl) {
        updateLocalStorage(convertedUrl);
        setPreviewLoading(true);
        setPreviewUrl(url);
        loadedApps.current = [];
        setEncodedUrl(routes.quickPreview(url));
        setIframeSrcDoc(undefined);
      }
    }
  };

  const navigateToErrorPage = () => {
    setIframeSrcDoc(errorPage);
  };

  const reloadWithQueryParam = (key: string, value: string) => {
    let newUrl = appendQueryParam(encodedUrl, key, value);
    newUrl = appendQueryParam(newUrl, `ts`, Date.now().toString());
    loadedApps.current = [];
    setPreviewLoading(true);
    setEncodedUrl(newUrl);
  };

  useEffect(() => {
    if (previewUrl.includes(`shopify_checkout_extensibility`)) {
      const quickPreviewUrl = routes.quickPreview(previewUrl);
      setEncodedUrl(quickPreviewUrl);
    }
  }, [editorDevice, previewUrl]);

  useEffect(() => {
    if (typeof currentExperience?.themeId === `number` && isThemesEnabled) {
      const url = appendThemeId(previewUrl, currentExperience);
      setPreviewUrl(url);
      navigateTo(url);
    }
  }, [currentExperience?.themeId]);

  useEffect(() => {
    setQuickPreviewInfo({
      url: previewUrl,
      placement: pageKindToPlacementKind(urlToStorePage(previewUrl).kind),
      device: currentExperience.quickPreviewDevice,
    });
  }, [previewUrl]);

  useEffect(() => {
    if (
      JSON.stringify(lastPlacement) !==
      JSON.stringify(currentExperience?.targeting?.placement)
    ) {
      if (
        ![
          PlacementKind.ALL_PAGES,
          PlacementKind.MINI_CART,
          PlacementKind.OTHER,
        ].includes(currentExperience?.targeting?.placement?.kind)
      ) {
        navigateTo(placementToUrl());
      }
      setLastPlacement(currentExperience?.targeting?.placement);
    }
  }, [currentExperience]);

  const generateCheckoutUrlForPreview = (): string => {
    const ids = topProducts(2)
      .map((p) => `${p.extra?.[0] as number}:1`)
      .join(`,`);
    return `${account.store.domain}/cart/${ids}`;
  };

  return {
    generateCheckoutUrlForPreview,
    encodedUrl,
    encodedUrlRef,
    previewUrl,
    iframeSrcDoc,
    getRelatedUrls,
    navigateTo,
    urlToStorePage,
    reloadWithQueryParam,
    navigateToErrorPage,
  };
}

function isEmpty(str: string): boolean {
  return str && str?.length > 0;
}

function getMockCheckoutUrl(alias: string, checkoutType: CheckoutType): string {
  if (checkoutType === CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY) {
    return CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY.toLowerCase().toString();
  }
  return legacyShopifyCheckoutSnapshotUrl(alias);
}

function legacyShopifyCheckoutSnapshotUrl(alias: string): string {
  let prefix = `https://sdk.loomi-stg.xyz`;
  if (routes.getEnv() === 2) {
    prefix = `https://sdk.loomi-prod.xyz`;
  }
  return `${prefix}/media/snapshots/checkout_info_${alias}.html`;
}

export function buildCatalogUrls(
  account: AccountDetails,
  collections: Collection[],
  products: Product[],
  checkoutType: CheckoutType,
): StorePage[] {
  const popularCollections =
    products
      ?.sort((a, b) => a.rank - b.rank)
      ?.slice(0, 20)
      ?.flatMap((prd) => prd.collections as string[])
      ?.reduce((result, current) => {
        if (!result[current]) {
          result[current] = 1;
        } else {
          result[current] += 1;
        }
        return result;
      }, {}) || [];

  const pages = [
    {
      url: `${account.store.domain}`,
      caption: `Homepage`,
      kind: PageKind.HOMEPAGE,
      rank: 1,
    },
    {
      url: `${account.store.domain}/cart`,
      caption: `Cart`,
      kind: PageKind.CART,
      rank: 1,
    },
    {
      url: `${account.store.domain}/cart?empty=true`,
      caption: `Cart (Empty)`,
      kind: PageKind.CART,
      rank: 1,
    },
    {
      url: getMockCheckoutUrl(account.store.alias, checkoutType),
      caption: `Checkout`,
      kind: PageKind.CHECKOUT,
      rank: 1,
    },
    ...collections.map((col) => ({
      url: handleToUrl(account, `collections`, col.handle),
      caption: `${col.title} (Category)`,
      kind: PageKind.COLLECTION,
      rank: popularCollections?.[col.handle]
        ? popularCollections?.[col.handle]
        : 999,
    })),
    ...(products?.map((prd) => productToPage(account, prd, products)) || []),
  ];

  const { isCartSelectorExist } = useContext(AccountContext);

  const miniCartPage = {
    url: `${account.store.domain}/?${MINI_CART_TAG}=true`,
    caption: `Cart Drawer`,
    kind: PageKind.MINI_CART,
    rank: 1,
  };
  return [
    ...pages.filter(
      (v: StorePage, i: number, self: StorePage[]) =>
        self.findIndex((v2) => v2.url === v.url) === i,
    ),
    ...(isCartSelectorExist ? [miniCartPage] : []),
  ];
}

function productToPage(
  account: AccountDetails,
  product: Product,
  products: Product[],
): StorePage {
  let { title } = product;
  if (products?.filter((product) => product?.title === title).length > 0) {
    const extractWords = (str: string) => str.split(/\W+/).filter(Boolean);
    const wordsTitle = new Set(extractWords(product?.title?.toLowerCase()));
    const wordsHandle = new Set(extractWords(product?.handle?.toLowerCase()));
    wordsTitle.forEach((word) => {
      if (wordsHandle.has(word)) {
        wordsHandle.delete(word);
      }
    });
    const variantWords = ` [${Array.from(wordsHandle).join(` `)}]`;
    title = `${product?.title}${
      Array.from(wordsHandle).length > 0 ? variantWords : ``
    }`;
  }
  return {
    url: handleToUrl(account, `products`, product.handle),
    caption: `${title} (Product)`,
    kind: PageKind.PRODUCT,
    rank: product.rank,
    extra: product.variantIds,
  };
}

export function buildAllUrls(
  account: AccountDetails,
  collections: Collection[],
  products: Product[],
) {
  const cols = (collections ?? [])?.map((col) => ({
    url: handleToUrl(account, `collections`, col.handle),
    caption: `${col.title} (Category)`,
  }));
  const prods = (products ?? [])
    ?.sort()
    ?.reverse()
    ?.map((prd) => ({
      url: handleToUrl(account, `products`, prd.handle),
      caption: `${prd.title} (Product)`,
    }));

  return [
    { url: `${account.store.domain}`, caption: `Homepage` },
    { url: `${account.store.domain}/cart`, caption: `Cart` },
    { url: `${account.store.domain}/checkout`, caption: `Checkout` },
    { url: `${account.store.domain}`, caption: `Cart Drawer` },
    ...(cols ?? []),
    ...(prods ?? []),
  ];
}

export interface StorePage {
  url: string;
  caption: string;
  kind: PageKind;
  rank: number;
  extra?: any;
}

export enum PageKind {
  PRODUCT = `PRODUCT`,
  COLLECTION = `COLLECTION`,
  CART = `CART`,
  MINI_CART = `MINI_CART`,
  HOMEPAGE = `HOMEPAGE`,
  CHECKOUT = `CHECKOUT`,
  CUSTOM = `CUSTOM`,
}

function pageKindToPlacementKind(kind: PageKind): PlacementKind {
  switch (kind) {
    case PageKind.PRODUCT:
      return PlacementKind.ALL_PRODUCTS;
    case PageKind.COLLECTION:
      return PlacementKind.ALL_CATEGORIES;
    case PageKind.CART:
      return PlacementKind.CART;
    case PageKind.HOMEPAGE:
      return PlacementKind.HOMEPAGE;
    case PageKind.CHECKOUT:
      return PlacementKind.CHECKOUT;
    case PageKind.MINI_CART:
      return PlacementKind.MINI_CART;
    case PageKind.CUSTOM:
      return PlacementKind.OTHER;
    default:
      return PlacementKind.HOMEPAGE;
  }
}

function handleToUrl(account: AccountDetails, prefix: string, handle: string) {
  return `${account?.store?.domain}/${prefix}/${handle}`;
}

function appendThemeId(url: string, currentExperience: Experience) {
  try {
    const l = new URL(url);
    l.searchParams.delete(PREVIEW_THEME_ID);
    if (
      currentExperience.mainThemeId === currentExperience.themeId ||
      `${currentExperience.themeId}` === `0`
    ) {
      l.searchParams.append(PREVIEW_THEME_ID, ``);
    } else if (currentExperience.themeId > 0) {
      l.searchParams.append(
        PREVIEW_THEME_ID,
        currentExperience.themeId.toString(),
      );
    }
    return l.toString();
  } catch (e) {
    return url;
  }
}

function getDefaultPreviewUrl(
  currentExperience: Experience,
  defaultUrl: string,
) {
  const url = isEmpty(currentExperience.quickPreviewUrl)
    ? currentExperience.quickPreviewUrl
    : defaultUrl;
  if (currentExperience?.themeId) {
    return appendThemeId(url, currentExperience);
  }
  return url;
}
