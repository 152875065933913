import { useContext, useMemo, useState } from 'react';
import produce from 'immer';
import { Placement, PlacementKind } from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { centered } from '@/components/use-shared-element';
import { usePlacementQueryBuilder } from '@/features/editor/widgets/shared/modals/use-placement-query-builder';
import { QBItemSelection } from '@/components/query-builder/models';
import { AccountContext } from '@/features/account-context';
import { usePostPurchaseNav } from '@/features/editor/widgets/post-purchase/use-post-purchase-nav';

export interface PlacementModel {
  kind: PlacementKind;
  name: string;
  description: string;
  value?: Placement;
  image: string;
}

export function usePlacementModal(setIsVisible: (value: boolean) => void) {
  const {
    account: {
      store: { alias, domain, isShopifyPlus, hasCheckout, isTestStore },
    },
    checkoutType,
  } = useContext(AccountContext);

  const {
    resources,
    deviceNavigation,
    devicePreview,
    experienceState: { currentExperience, setTargetingPlacement },
  } = useContext(EditorContext);

  const { experienceCreationVersion } = currentExperience;

  const {
    placementProps,
    pageType,
    categories,
    products,
    productsFromCollections: pCollections,
    tags,
    pageUrl,
  } = usePlacementQueryBuilder(
    resources,
    experienceCreationVersion,
    currentExperience?.targeting?.placement?.other,
    isShopifyPlus,
  );

  const { hasActivePostPurchase } = resources;

  const postPurchasePage: PlacementModel = {
    kind: PlacementKind.OTHER,
    name: `${hasActivePostPurchase ? `Add Another` : `Add`} Post Purchase`,
    description: hasActivePostPurchase
      ? `Target post-purchase page`
      : `Enhance Your Post-Purchase Experience with an Exclusive Offer`,
    image: `postPurchase`,
    value: { kind: PlacementKind.OTHER, other: undefined },
  };

  const allPages: PlacementModel = {
    kind: PlacementKind.ALL_PAGES,
    name: `All Pages`,
    description: `Literally, all of your store pages`,
    image: `all`,
    value: { kind: PlacementKind.ALL_PAGES, other: undefined },
  };

  const homePage: PlacementModel = {
    kind: PlacementKind.HOMEPAGE,
    name: `Homepage`,
    description: `Your home page`,
    image: `home`,
    value: { kind: PlacementKind.HOMEPAGE, other: undefined },
  };

  const allCollectionsPages: PlacementModel = {
    kind: PlacementKind.ALL_CATEGORIES,
    name: `Collection Pages`,
    description: `Target all or some of your collection pages`,
    image: `plp`,
    value: {
      kind: PlacementKind.ALL_CATEGORIES,
      other: undefined,
    },
  };

  const allProductsPages: PlacementModel = {
    kind: PlacementKind.ALL_PRODUCTS,
    name: `Product pages`,
    description: `Target all or some of your product pages`,
    image: `pdp`,
    value: { kind: PlacementKind.ALL_PRODUCTS, other: undefined },
  };

  const cartPage: PlacementModel = {
    kind: PlacementKind.CART,
    name: `Cart Page`,
    description: `Your cart page`,
    image: `cart`,
    value: { kind: PlacementKind.CART, other: undefined },
  };

  const miniCartPage: PlacementModel = {
    kind: PlacementKind.MINI_CART,
    name: `Cart Drawer Page`,
    description: `Your cart drawer page`,
    image: `cart`,
    value: { kind: PlacementKind.MINI_CART, other: undefined },
  };

  const checkoutPage: PlacementModel = {
    kind: PlacementKind.CHECKOUT,
    name: `Checkout Page`,
    description: `Customize your checkout page (Shopify Plus)`,
    image: `checkout`,
    value: { kind: PlacementKind.CHECKOUT, other: undefined },
  };

  const customUrl: PlacementModel = {
    kind: PlacementKind.OTHER,
    name: `Custom Url`,
    description: `Target specific pages by their urls`,
    image: `url`,
    value: {
      kind: PlacementKind.OTHER,
      other: [
        {
          qbProps: pageUrl,
          values: [
            {
              op: `includes`,
              value: domain,
            },
          ],
        },
      ],
    },
  };

  const otherPage: PlacementModel = {
    kind: PlacementKind.OTHER,
    name: `Other`,
    description: `Build your own page rule`,
    image: `other`,
    value: { kind: PlacementKind.OTHER, other: undefined },
  };

  const specificCollections: Placement = {
    kind: PlacementKind.OTHER,
    other: [
      {
        qbProps: pageType,
        values: [
          {
            op: `is`,
            value: pageType?.options?.[3]?.key as string,
          },
        ],
      },
      {
        qbProps: categories,
        values: [
          {
            op: `is`,
            value: categories?.options?.[0]?.key as string,
          },
        ],
      },
    ],
  };

  const productFromCollection: Placement = {
    kind: PlacementKind.OTHER,
    other: [
      {
        qbProps: pageType,
        values: [
          {
            op: `is`,
            value: pageType?.options?.[4]?.key as string,
          },
        ],
      },
      {
        qbProps: pCollections,
        values: [
          {
            op: `is`,
            value: pCollections?.options?.[0]?.key as string,
          },
        ],
      },
    ],
  };

  const productFromTags: Placement = {
    kind: PlacementKind.OTHER,
    other: [
      {
        qbProps: pageType,
        values: [
          {
            op: `is`,
            value: pageType?.options?.[4]?.key as string,
          },
        ],
      },
      {
        qbProps: tags,
        values: [
          {
            op: `is`,
            value: tags?.options?.[0]?.key as string,
          },
        ],
      },
    ],
  };

  const specificProducts: Placement = {
    kind: PlacementKind.OTHER,
    other: [
      {
        qbProps: pageType,
        values: [
          {
            op: `is`,
            value: pageType?.options?.[4]?.key as string,
          },
        ],
      },
      {
        qbProps: products,
        values: [
          {
            op: `is`,
            value: products?.options?.[0]?.key as string,
          },
        ],
      },
    ],
  };

  const toRect = centered(65, 90);

  const [selected, setSelected] = useState(
    currentExperience.targeting.placement,
  );

  const onSelection = (value: boolean, placement: Placement) => {
    if (!value) {
      setSelected(placement);
    }
  };

  const onSave = () => {
    setTargetingPlacement({ ...selected });
    setIsVisible(false);

    if (selected.kind === `ALL_CATEGORIES`) {
      const collection = resources?.collections?.[0];
      collection &&
        deviceNavigation.navigateTo(
          `${domain}/collections/${collection.handle}`,
        );
      return;
    }

    if (selected.kind === `ALL_PRODUCTS`) {
      const product = resources?.products?.[0];
      product &&
        deviceNavigation.navigateTo(`${domain}/products/${product.handle}`);
      return;
    }

    if (selected.kind === PlacementKind.MINI_CART) {
      devicePreview.sendJsCode(`window.loomi_api?.openDrawer()`);
      return;
    }

    const collectionHandle = getSelection(selected, `Categories`);
    if (collectionHandle) {
      deviceNavigation.navigateTo(`${domain}/collections/${collectionHandle}`);
      return;
    }

    const productInCollection = getSelection(
      selected,
      `Products From Collections`,
    );
    if (productInCollection) {
      const product = resources?.products?.find((p) =>
        p.collections?.includes(productInCollection),
      )?.handle;
      if (product) {
        deviceNavigation.navigateTo(`${domain}/products/${product}`);
        return;
      }
    }
    const specificProduct = getSelection(selected, `Products`);
    if (specificProduct) {
      deviceNavigation.navigateTo(`${domain}/products/${specificProduct}`);
      return;
    }

    const tag = getSelection(selected, `Tag`);
    if (tag) {
      const product = resources?.products?.find((p) =>
        p.tags?.includes(tag),
      )?.handle;
      if (product) {
        deviceNavigation.navigateTo(`${domain}/products/${product}`);
        return;
      }
    }

    const pageType = getSelection(selected, `Page Type`);
    if (pageType === `.*/products/.*`) {
      deviceNavigation.navigateTo(
        `${domain}/products/${resources?.products[0].handle}`,
      );
      return;
    }

    if (pageType === `.*/collections/.*`) {
      const collection = resources?.collections?.[0];
      collection &&
        deviceNavigation.navigateTo(`${domain}/collections/${collection}`);
      return;
    }

    if (pageType === `/`) {
      deviceNavigation.navigateTo(`${domain}/`);
      return;
    }
    if (pageType === `.*/cart`) {
      deviceNavigation.navigateTo(`${domain}/cart`);
      return;
    }

    const url = getSelection(selected, `Page Url`);
    if (url) {
      deviceNavigation.navigateTo(url);
      return;
    }

    const urlWithDomain = getSelection(selected, `Page Url (WITH DOMAIN)`);
    if (urlWithDomain) {
      deviceNavigation.navigateTo(urlWithDomain);
      return;
    }
    if (
      getSelection(selected, `Product Out Of Stock`) ||
      hasCaption(selected, `Product Price`)
    ) {
      deviceNavigation.navigateTo(
        `${domain}/products/${resources?.products[0].handle}`,
      );
    }
  };

  const onBackToSelection = () => {
    const updated = produce(selected, (draft) => {
      draft.kind = PlacementKind.ALL_PAGES;
      draft.other = [];
    });
    setSelected(updated);
    setIsCheckoutEnableStep(false);
  };

  const onQueryBuilderChange = (value: QBItemSelection[]) => {
    const updated = produce(selected, (draft) => {
      draft.kind = PlacementKind.OTHER;
      draft.other = value;
    });
    setSelected(updated);
  };

  const isCustomTargeting = useMemo(
    () => selected.kind === PlacementKind.OTHER,
    [selected],
  );

  const [isCheckoutEnableStep, setIsCheckoutEnableStep] = useState(false);

  const { navigateToPostPurchaseExperience } = usePostPurchaseNav();

  const onPostPurchase = async () => {
    navigateToPostPurchaseExperience().then();
  };

  return {
    alias,
    allPages,
    homePage,
    allCollectionsPages,
    allProductsPages,
    cartPage,
    miniCartPage,
    checkoutPage,
    postPurchasePage,
    customUrl,
    otherPage,
    toRect,
    placementProps,
    onSelection,
    selected,
    onSave,
    onPostPurchase,
    onBackToSelection,
    onQueryBuilderChange,
    isCustomTargeting,
    specificCollections,
    productFromCollection,
    productFromTags,
    specificProducts,
    isShopifyPlus,
    hasCheckout,
    isCheckoutEnableStep,
    setIsCheckoutEnableStep,
    checkoutType,
    isTestStore,
  };
}

function hasCaption(selected: Placement, caption: string) {
  try {
    return !!selected.other.find((v) => v.qbProps.caption === caption);
  } catch {
    return undefined;
  }
}

function getSelection(selected: Placement, caption: string) {
  try {
    const values = selected.other
      .find((v) => v.qbProps.caption === caption)
      .values?.filter((v) => v.op === `is`)
      .map((v) => v.value);
    return values ? values[values.length - 1] : undefined;
  } catch {
    return undefined;
  }
}
