/* eslint-disable no-unsafe-optional-chaining */
import moment from 'moment';
import {
  EditorDeclarativeBlock,
  Experience,
  ExperienceStatus,
  ExperienceVariant,
  PlacementKind,
  Schedule,
  ScheduleType,
  TargetingDeviceType,
} from '@/webapi/use-experience-api';
import { MetricKind, MetricValue } from '@/webapi/models';
import { DeviceType } from '@/utils/definitions';
import {
  get24HourTimeString,
  maybe,
  utcInSecToDate,
} from '@/features/details/utils';
import { formatEnum } from '@/utils/types';
import { CONTROL } from '@/features/details/shared';

export function shouldSync(
  currentExperience: Experience,
  ch: EditorDeclarativeBlock,
): boolean {
  if (ch.isDisabled === true) return false;
  if (
    currentExperience.quickPreviewPlacement === ch?.targetPlacement?.kind ||
    [
      PlacementKind.ALL_PAGES,
      PlacementKind.MINI_CART,
      PlacementKind.OTHER,
      undefined,
    ].includes(ch?.targetPlacement?.kind)
  ) {
    if (ch.targetDevice === TargetingDeviceType.ALL_DEVICES) {
      return true;
    }
    if (
      currentExperience.quickPreviewDevice === DeviceType.Mobile &&
      ch.targetDevice === TargetingDeviceType.MOBILE
    ) {
      return true;
    }

    if (
      currentExperience.quickPreviewDevice === DeviceType.Desktop &&
      ch.targetDevice === TargetingDeviceType.DESKTOP
    ) {
      return true;
    }
  }
  return false;
}

export function getRelativeMetric(
  experience: Experience,
  kind: MetricKind,
): {
  formatted: string;
  raw: number;
  color: string;
  best?: string;
  worst?: string;
} {
  const sessions = metricsByKind(experience, MetricKind.SESSIONS);

  if (
    [
      MetricKind.PER_SESSION_VALUE,
      MetricKind.AVG_ORDER_VALUE,
      MetricKind.CONVERSION_RATE,
      MetricKind.CHECKOUT_RATE,
      MetricKind.AVG_SESSION_DURATION,
      MetricKind.ITEMS_PER_PURCHASE,
      MetricKind.PRODUCT_DETAILS_VIEWS_RATE,
      MetricKind.PAGES_PER_SESSION,
      MetricKind.ADD_TO_CART_RATE,
      MetricKind.SIGNUPS_RATE,
      MetricKind.SUBSCRIPTION_RATE,
      MetricKind.CTR,
      MetricKind.PROFIT_PER_SESSION,
      MetricKind.RETURNS_PER_SESSION,
    ].includes(kind)
  ) {
    sessions.control = 1;
    sessions.variations = sessions.variations.map((v) => {
      v.value = 1;
      return v;
    });
  }

  const metrics = metricsByKind(experience, kind);
  const control = metrics.control > 0 ? metrics.control / sessions.control : 1;
  let noneZeroVariation = metrics.variations.findIndex((v) => v.value > 0);
  if (metrics.controlObj) {
    noneZeroVariation = metrics.variations.findIndex(
      (v) => v.name === metrics.variations[0].name,
    );
  }
  const variation1 = maybe(
    () =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      metrics.variations?.[noneZeroVariation]?.value /
        sessions.variations?.[noneZeroVariation]?.value || 0,
    0,
  );
  const fraction = variation1 / control;
  const change =
    fraction === 0 || fraction === variation1 ? 0 : fraction * 100 - 100;

  let color = `#5b6971`;
  if (change > 0.2) {
    color = `#80D679`;
  } else if (change < -0.1) {
    color = `#ED6B6B`;
  }

  return {
    formatted: `${change > 0 ? `+` : ``}${change.toFixed(2)}%`,
    raw: change,
    color,
    best: metrics?.controlObj ? metrics?.variations?.[0]?.name : undefined,
    worst: metrics?.controlObj ? metrics?.controlObj?.name : undefined,
  };
}

export function getMetric(
  experience: Experience,
  kind: MetricKind,
  variationName?: string,
): MetricValue {
  const metrics = metricsByKind(experience, kind);
  if (variationName) {
    if (variationName === `CONTROL`) {
      return {
        name: kind,
        value: metrics.control,
      };
    }
    return {
      name: kind,
      value:
        metrics.variations.filter((m) => m.name === variationName)?.[0]
          ?.value || 0,
    };
  }

  return {
    name: kind,
    value: metrics.variations?.[0].value,
  };
}

export function getFormattedMetric(
  experience: Experience,
  kind: MetricKind,
  variationName?: string,
): string {
  return nFormatter(getMetric(experience, kind, variationName).value, 2);
}

export function nFormatter(num, digits) {
  if (num < 1 && num > 0) {
    return num.toFixed(2);
  }

  const lookup = [
    { value: 1, symbol: `` },
    { value: 1e3, symbol: `k` },
    { value: 1e6, symbol: `M` },
    { value: 1e9, symbol: `G` },
    { value: 1e12, symbol: `T` },
    { value: 1e15, symbol: `P` },
    { value: 1e18, symbol: `E` },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, `$1`) + item.symbol
    : `0`;
}

export function metricsByKind(experience: Experience, kind: MetricKind) {
  const metrics = experience?.variants?.map(takeMetric(kind));

  const control = metrics?.find((m) => m.name === CONTROL)?.value || 0;
  const variations = metrics?.filter((m) => m.name !== CONTROL);
  if (hasActiveControlAndMoreThan1Variation(experience)) {
    // https://loomi-labs.atlassian.net/browse/LVP-2426
    return {
      control,
      variations: [takeBestVariation(variations)],
    };
  }

  if (noActiveControlAndMoreThan1Variation(experience)) {
    const fakeControl = takeWorstVariation(variations);
    return {
      control: fakeControl.value,
      variations: [takeBestVariation(variations)],
      controlObj: fakeControl,
    };
  }

  if (
    multiVariationNoControl(control, variations, kind) &&
    !experience.variants.find((v) => v.publishedChance === 100)
  ) {
    return {
      control: variations[0].value,
      variations: [variations[1]],
    };
  }

  return {
    control,
    variations,
  };
}

function multiVariationNoControl(
  control: number,
  variations: { kind: MetricKind; name: string; value: number }[],
  kind: MetricKind,
) {
  return (
    control === 0 && variations.length === 2 && kind !== MetricKind.SESSIONS
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function naturalSorting(a: ExperienceVariant, b: ExperienceVariant) {
  if (a.name === CONTROL) {
    return -1;
  }
  return 1;
}

export function getStatusForScheduledExperien(
  experience: Experience,
): ExperienceStatus {
  if (
    experience?.status === ExperienceStatus.PUBLISHED &&
    experience?.schedule?.type !== undefined &&
    !experience?.isScheduleActive
  ) {
    return ExperienceStatus.SCHEDULED;
  }
  return experience?.status;
}

export function parseScheduledTime(schedule: Schedule): string {
  if (schedule?.type === ScheduleType.SPECIFIC_DATE) {
    let startDate = utcInSecToDate(schedule.utcStart);

    const offset = schedule?.timeZone?.offset || 0;
    startDate = new Date(startDate.getTime() + offset * -3600);

    let endDate = utcInSecToDate(schedule?.utcEnd);
    endDate = new Date(endDate.getTime() + offset * -3600);
    return `on ${moment(startDate).utc().format(`DD/MM/yyyy`)} to ${moment(
      endDate,
    )
      .utc()
      .format(`DD/MM/yyyy`)}`;
  }
  if (schedule?.type === ScheduleType.EVERY_DAY_AT_A_CERTAIN_TIME) {
    return `on every day from ${get24HourTimeString(
      schedule?.startHour,
      schedule?.startMinute,
    )} to ${get24HourTimeString(schedule?.endHour, schedule?.endMinute)}`;
  }
  if (schedule?.type === ScheduleType.EVERY_WEEK_ON_A_CERTAIN_DAY) {
    return `on every ${formatEnum(
      schedule?.weekDay,
      true,
    )} from ${get24HourTimeString(
      schedule?.startHour,
      schedule?.startMinute,
    )} to ${get24HourTimeString(schedule?.endHour, schedule?.endMinute)}`;
  }
  return ``;
}

function hasActiveControlAndMoreThan1Variation(experience: Experience) {
  return (
    !!experience.variants.find(
      (v) => v.name === CONTROL && v.publishedChance > 0,
    ) &&
    experience.variants.filter(
      (v) => v.name !== CONTROL && v.publishedChance > 0,
    ).length > 1
  );
}

function noActiveControlAndMoreThan1Variation(experience: Experience) {
  return (
    (!!experience.variants.find(
      (v) =>
        v.name === CONTROL && (v.publishedChance === 0 || !v.publishedChance),
    ) ||
      !experience.variants.find((v) => v.name === CONTROL)) &&
    experience.variants.filter(
      (v) => v.name !== CONTROL && v.publishedChance > 0,
    ).length > 1
  );
}

function takeBestVariation(
  variations: { kind: MetricKind; name: string; value: number }[],
) {
  return variations.sort((a, b) => b.value - a.value)[0];
}

function takeWorstVariation(
  variations: { kind: MetricKind; name: string; value: number }[],
) {
  return variations.sort((a, b) => a.value - b.value)[0];
}

function takeMetric(kind: MetricKind) {
  return (v) => {
    const temp = v?.metrics?.filter((m) => m.name === kind);
    let value = 0;
    if (temp?.length > 0) {
      value = temp[0].value;
    }

    return {
      name: v.name,
      kind,
      value,
    };
  };
}
