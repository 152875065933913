import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { GenericInputProps } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { CardWrapper } from '@/features/editor/widgets/custom-widget/inputs/shared/card-wrapper';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { Flexbox } from '@/components/flex';
import { Caption } from '../../shared/shared-styles';

import { UndoRedoCounterContext } from '@/features/editor/widgets/custom-widget/shared/undo-redo-counter-context';
import { CustomWidgetContext } from '@/features/editor/widgets/custom-widget/shared/context';
import { maybe } from '@/features/details/utils';

export function SpacingInput({
  customization,
  initialValues,
  onValuesChanged,
  spec,
}: GenericInputProps) {
  const { undoRedoCount } = useContext(UndoRedoCounterContext);

  const getValue = useCallback(() => initialValues(`value`), [undoRedoCount]);
  const { currentSchema } = useContext(CustomWidgetContext);

  const parsedValue = parse(getValue());
  const [top, setTop] = useState(parsedValue[0]);
  const [left, setLeft] = useState(parsedValue[1]);
  const [bottom, setBottom] = useState(parsedValue[2]);
  const [right, setRight] = useState(parsedValue[3]);

  useEffect(() => {
    const parsedValue = parse(getValue());
    setTop(parsedValue[0]);
    setLeft(parsedValue[1]);
    setBottom(parsedValue[2]);
    setRight(parsedValue[3]);
  }, [undoRedoCount]);

  useEffect(() => {
    const value = format(top, left, bottom, right);
    onValuesChanged(`value`, value);
  }, [top, left, bottom, right]);

  const lockedTop = useMemo(
    () => isLocked(`top`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );
  const lockedLeft = useMemo(
    () => isLocked(`left`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );
  const lockedBottom = useMemo(
    () => isLocked(`bottom`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );
  const lockedRight = useMemo(
    () => isLocked(`right`),
    [customization.key, currentSchema?.settings?.general?.specs],
  );

  function isLocked(direction: string): boolean {
    // For ADD TO CART below product (Upsells)
    if (customization.key === `atcButton`) {
      const addCartPlacement = maybe(
        () =>
          currentSchema?.settings?.general?.specs?.find(
            (e) => e?.key === `addCartPlacement`,
          )?.value?.value ?? null,
      );

      const isPlacementProduct = addCartPlacement === `product`;
      const isDirectionSideways = direction === `right` || direction === `left`;

      if (isPlacementProduct && isDirectionSideways) {
        return true;
      }
    }
    return false;
  }

  return (
    <CardWrapper
      title={<Caption>{spec?.name ? spec?.name : `Spacing`}</Caption>}
    >
      <Wrapper>
        <Flexbox align="center">
          <NumberInput
            minimal
            locked={lockedTop}
            suffix="px"
            defaultValue={top}
            onChange={(val) => setTop(val)}
            min={-2000}
            max={2000}
          />
        </Flexbox>
        <NumberInput
          minimal
          locked={lockedLeft}
          suffix="px"
          defaultValue={left}
          onChange={(val) => setLeft(val)}
          min={-2000}
          max={2000}
        />

        <Rectangle />

        <NumberInput
          minimal
          locked={lockedRight}
          suffix="px"
          defaultValue={right}
          onChange={(val) => setRight(val)}
          min={-2000}
          max={2000}
        />
        <Flexbox align="center">
          <NumberInput
            minimal
            locked={lockedBottom}
            suffix="px"
            defaultValue={bottom}
            onChange={(val) => setBottom(val)}
            min={-2000}
            max={2000}
          />
        </Flexbox>
      </Wrapper>
    </CardWrapper>
  );
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 3rem;
  grid-column-gap: 1rem;
  grid-row-gap: 0.1rem;

  justify-content: center;
  align-items: center;

  && > :nth-child(1) {
    grid-column: span 6;
    grid-row-start: 1;
  }

  && > :nth-child(2) {
    grid-column: span 1;
    grid-row-start: 2;
  }

  && > :nth-child(3) {
    grid-column: span 4;
    grid-row-start: 2;
  }

  && > :nth-child(4) {
    grid-column: span 1;
    grid-row-start: 2;
  }

  && > :nth-child(5) {
    grid-column: span 6;
    grid-row-start: 3;
  }
`;

const Rectangle = styled.div`
  width: 100%;
  height: 50%;
  border: 1px solid #e4eaed;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11),
    0 4px 13px 0 rgba(97, 121, 129, 0.19);
`;

function parse(paddingStr: string): string[] {
  return paddingStr.split(` `);
}

function format(top: string, left: string, bottom: string, right: string) {
  return `${top} ${left} ${bottom} ${right}`;
}
