/* eslint-disable no-console */
import { CachePolicies, useFetch } from 'use-http';
import produce from 'immer';
import { routes } from '@/webapi/routes';
import { generateUUID, handleJsonResult, handleResult } from '@/webapi/common';
import { MetricKind, MetricValue } from '@/webapi/models';
import {
  FacebookTargeting,
  QBItemSelection,
} from '@/components/query-builder/models';
import {
  CatalogWidgetProps,
  CustomizationSpec,
  PageRedirectOptions,
} from '@/webapi/use-widget-catalog-api';
import { DeviceType } from '@/utils/definitions';
import { getCountryCode } from '@/utils/country-codes';
import { removeExperienceFromCache } from '@/components/hooks/use-cached-auto-save';
import { AbTestResult, ExpVersion } from '@/webapi/use-analytics-api';
import { PostPurchaseProps } from '@/features/editor/widgets/post-purchase/props';
import { DeclarativeBlock, HtmlMutationKind, WidgetChange } from '@/pkg/sdk';
import { ExperienceCreationVersion } from '@/webapi/experience-creation-version';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import {
  StyleTemplate,
  StyleTemplateType,
} from '@/features/editor/widgets/custom-widget/style-templating/models';
import { PREVIEW_THEME_ID } from '@/utils/types';
import { CatalogCheckoutWidget } from '@/webapi/checkout-widget-props';
import { CheckoutNewComponent } from '@/features/editor/context/use-device-preview';
import { cleanupLoadingEnvOptions } from '@/features/editor/widgets/custom-widget/env';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { Audience } from '@/features/dashboard/audiences/models';

export const UNNAMED_EXPERIENCE = `Unnamed Experience`;

export function useExperienceApi(
  deviceType?: DeviceType,
  experience?: Experience,
  initialLoading = false,
) {
  const device = deviceType === DeviceType.Desktop ? `d` : `m`;
  const countryCode = getCountryCode(experience?.targeting?.other);

  const { response, get, post, put, del, error, loading } = useFetch(
    ``,
    (globalOptions) => ({
      ...globalOptions,
      ...{
        credentials: `include`,
        cachePolicy: CachePolicies.NO_CACHE,
        loading: initialLoading,
      },
    }),
  );

  const isUnSaveRecOptsEnabled = useFeatureBit(FeatureBit.UNSAVE_FILTER_OPTS);

  const upsertExperience = async (exp: Experience): Promise<Experience> => {
    await removeExperienceFromCache(exp.id);
    if (isUnSaveRecOptsEnabled) {
      exp = produce(clone(exp) as Experience, (exp) => {
        exp.variants?.forEach((v) =>
          v.changes?.forEach((x) => {
            cleanupLoadingEnvOptions(
              x.widgetProps?.settings?.loading?.loadingEnv,
            );
          }),
        );
      });
    }

    const newExp = (await put(routes.upsertExperience(), exp)) as Experience;
    fixVariantTargetAllDevices(newExp);
    return newExp;
  };

  const autoSaveStyles = async (
    exp: Experience,
    type: StyleTemplateType.EDIT_LATEST | StyleTemplateType.PUBLISH_LATEST,
  ): Promise<void> => {
    try {
      const devicePrefix = deviceType === DeviceType.Mobile ? `M` : `D`;
      const suffix =
        type === StyleTemplateType.PUBLISH_LATEST
          ? `(LATEST PUBLISHED)`
          : `(LATEST EDIT)`;
      const styles = exp?.variants
        ?.flatMap?.((v) =>
          (v?.changes || [])
            .filter((c) => c.targetDevice?.toLowerCase() === deviceType)
            .map((c) => c.widgetProps),
        )
        ?.filter?.((p) => !!p)
        ?.map?.(
          (p) =>
            ({
              device: devicePrefix,
              type,
              id: `${devicePrefix} ${p.blueprintName} ${suffix}`,
              widgetName: p.blueprintName,
              appId: p.appId,
              props: { customizations: p.customizations },
              generalSettings: p.settings?.general,
              name: `${devicePrefix} ${p.blueprintName} ${suffix}`,
            } as StyleTemplate),
        )
        ?.filter(
          (s) =>
            !!s?.props?.customizations && s?.props?.customizations?.length > 0,
        );
      if (styles.length === 0) {
        return Promise.resolve();
      }
      return post(routes.autoSaveStyles(), {
        styles,
        type,
      });
    } catch (e) {
      return Promise.resolve();
    }
  };

  const deleteExperience = async (id: string) => {
    await del(routes.deleteExperience(id));
    return handleResult(response, error);
  };

  const getExperience = async (
    id: string,
  ): Promise<{ experience: Experience }> => {
    const exp: Experience = (await get(routes.findExperience(id)))?.experience;
    if (!exp?.targeting?.placement?.kind && !!exp?.targeting?.placement) {
      exp.targeting.placement = {
        kind: PlacementKind.ALL_PAGES,
      };
    }
    fixVariantTargetAllDevices(exp);
    return { experience: exp };
  };

  const fixVariantTargetAllDevices = (exp: Experience) => {
    try {
      exp?.variants?.forEach((variant, vidx) => {
        variant?.changes?.forEach((change, cidx) => {
          if (!change?.targetDevice) {
            exp.variants[vidx].changes[cidx].targetDevice =
              TargetingDeviceType.ALL_DEVICES;
          }
        });
      });
    } catch (ex) {
      console.error(
        `vsly`,
        `failed to fix variant targeting all devices with error`,
        ex,
      );
    }
  };

  const listExperiences = async (
    page: number,
    size: number,
    options?: ListOptions,
  ): Promise<ExperienceList> => {
    const searchString = options?.search || ``;
    const labels = options?.labels?.length > 0 ? options.labels : undefined;
    const filter = options?.filter || ExperienceFilter.UNKNOWN_STATUS;
    const sort = options?.sort || {
      option: ExperienceSortOption.BY_PUBLISH_DATE,
      direction: ExperienceSortDirection.DESC,
    };
    await post(routes.listExperiences(), {
      page,
      size,
      filter,
      sort,
      searchString,
      labels,
      timezoneOffset: new Date().getTimezoneOffset(),
    });
    return (await response.json()) as ExperienceList;
  };

  const publishExperience = async (id: string, resetStats?: boolean) => {
    const req = { id, resetStats };
    await post(routes.publishExperience(), req);
    await removeExperienceFromCache(id);
    autoSaveStyles(experience, StyleTemplateType.PUBLISH_LATEST).then();
  };

  const updateExperienceAllocation = async (
    exp: Experience,
    variants: ExperienceVariant[],
  ) => {
    const { id } = exp;
    const req = { experienceId: id, variants };
    const resp = await put(routes.updateExperienceAllocation(), req);
    await removeExperienceFromCache(id);
    return resp as Experience;
  };

  const unpublishExperience = async (id: string) => {
    const req = { id };
    await post(routes.pauseExperience(id), req);
    await removeExperienceFromCache(id);
  };

  const archiveExperience = async (id: string) => {
    const req = { id };
    await post(routes.archiveExperience(id), req);
    await removeExperienceFromCache(id);
  };

  const duplicateExperience = async (experience: Experience, name: string) => {
    const req = { id: experience.id, name };
    const resp = await post(routes.duplicateExperience(), req);
    return handleJsonResult(resp, error);
  };

  const generatePreviewLink = async (
    expId: string,
    variantId: string,
    quickPreviewUrl: string,
    activeVariantId?: string,
    experience?: Experience,
  ): Promise<{ link: string }> =>
    post(routes.generatePreviewFromExperience, {
      experienceId: expId,
      variantId: activeVariantId || variantId,
      quickPreviewUrl,
      targetingOverride: {
        deviceKind: device,
        countryOverride: countryCode,
        experienceId: expId,
      },
      experience,
    });

  const isThemesPreviewEnabled =
    useFeatureBit(FeatureBit.SHOPIFY_THEMES) &&
    !useFeatureBit(FeatureBit.SPA_INTEGRATION);

  const generatePermanentPreviewLinks = async (
    expId: string,
    domain: string,
    themeId: number,
    mainThemeId?: number,
  ): Promise<{ links: VariantPreview[] }> => {
    let themeIdQuery = themeId as any;
    if (isThemesPreviewEnabled && themeIdQuery > 0) {
      themeIdQuery = `${PREVIEW_THEME_ID}=${
        themeId === mainThemeId || `${themeId}` === `0` ? `` : themeId
      }`;
    } else {
      themeIdQuery = `${PREVIEW_THEME_ID}=`;
    }
    return post(routes.generatePermanentPreviewFromExperience, {
      experienceId: expId,
      themeId: themeIdQuery,
      domain,
    });
  };

  const sharePreviewLink = async (
    req: SharePreviewReq,
  ): Promise<{ link: string }> => post(routes.sharePreview(), req);

  const listAudiences = () =>
    post(routes.audiencesList, { onlyForTargeting: true }) as Promise<{
      audiences: Array<Audience>;
    }>;

  const getExperienceInfo = async (
    expId: string,
  ): Promise<ExperienceInfoResponse> => {
    const response = await get(routes.getExperienceInfo(expId));
    return response as ExperienceInfoResponse;
  };

  const updateExperienceInfo = async (
    info: ExperienceInfoRequest,
  ): Promise<ExperienceInfoResponse> => {
    const response = await put(routes.updateExperienceInfo(), info);
    return response as ExperienceInfoResponse;
  };

  const listLabels = async (): Promise<string[]> => {
    const response = await post(routes.listLabels());
    return response?.labels as string[];
  };

  const removeLabels = async (labels: string[]): Promise<string[]> => {
    const response = await post(routes.removeLabels(), { labels });
    return response?.labels as string[];
  };

  return {
    loading,
    error,
    autoSaveStyles,
    upsertExperience,
    deleteExperience,
    getExperience,
    listExperiences,
    publishExperience,
    unpublishExperience,
    generatePreviewLink,
    sharePreviewLink,
    archiveExperience,
    duplicateExperience,
    generatePermanentPreviewLinks,
    listAudiences,
    getExperienceInfo,
    updateExperienceInfo,
    listLabels,
    removeLabels,
    updateExperienceAllocation,
  };
}

export interface ListOptions {
  search?: string;
  filter?: ExperienceFilter;
  abTest?: boolean;
  sort?: ExperienceSort;
  labels?: string[];
}

export function draftExperience(
  isPostPurchase: boolean,
  expId?: string,
): Experience {
  expId = expId || generateUUID();
  const variantId = generateUUID();

  return {
    id: expId,
    experienceCreationVersion:
      ExperienceCreationVersion.VISUAL_EDIT_TEXT_NODE_FIX,
    isPostPurchase,
    name: isPostPurchase ? `POST PURCHASE` : UNNAMED_EXPERIENCE,
    lastDevicePreview: undefined,
    goals: [
      MetricKind.CONVERSION_RATE,
      MetricKind.PER_SESSION_VALUE,
      MetricKind.PROFIT,
    ],
    status: ExperienceStatus.DRAFT,
    quickPreviewPlacement: PlacementKind.HOMEPAGE,
    targeting: {
      segments: [],
      device: TargetingDeviceType.ALL_DEVICES,
      placement: {
        kind: isPostPurchase ? PlacementKind.OTHER : PlacementKind.ALL_PAGES,
      },
    },
    trigger: {
      kind: TriggerKind.PAGE_LOAD,
      selector: ``,
      timeoutMillis: 0,
    },
    variants: [
      {
        id: variantId,
        name: `Variation 1`,
        chance: 100,
        changes: defaultChanges(isPostPurchase),
        pageRegex: ``,
      },
    ],
    labels: [],
    beforeImages: [],
    afterImages: [],
  };
}

export interface ExperienceInfoRequest {
  id: string;
  name: string;
  isHidden: boolean;
  description: string;
  labels: string[];
  beforeImages: string[];
  afterImages: string[];
}

export interface ExperienceInfoResponse {
  id: string;
  name: string;
  isHidden: boolean;
  description: string;
  labels: SelectedLabel[];
  beforeImages: string[];
  afterImages: string[];
}

export interface SelectedLabel {
  label: string;
  selected: boolean;
}

export interface CompoundBlock {
  htmlKind: HtmlMutationKind;
  html: string;
  css: string;
  js: string;
}

export interface ExperienceList {
  experiences?: Experience[];
  total?: number;
  archived?: number;
  paused?: number;
  abTests?: number;
  oneHundredPercent?: number;
  draft?: number;
  running?: number;
  unfilteredTotal?: number;
}

export enum ExperienceTag {
  CUSTOM = `CUSTOM`,
  RECS = `RECS`,
  CONTENT = `CONTENT`,
}

export interface Experience {
  id: string;
  hideUplift?: boolean;
  name: string;
  description?: string;
  themeId?: number;
  sessionType?: string;
  mainThemeId?: number;
  maxVersionAtPublish?: number;
  resetStatsAtPublish?: boolean;
  tag?: ExperienceTag;
  activeVariantId?: string;
  isHidden?: boolean;
  variants: ExperienceVariant[];
  versions?: Array<ExpVersion>;
  currentVersion?: ExpVersion;
  maxVersion?: number;
  postPurchaseProps?: PostPurchaseProps;
  uplift?: {
    testVersion: number;
    revViaExp: number;
    storeRev: number;
    runningDays: number;
    cr: number;
    psv: number;
    aov: number;
  };
  trigger: Trigger;
  goals: MetricKind[];
  schedule?: Schedule;
  primaryGoal?: MetricKind;
  targeting: Targeting;
  publishedTargeting?: Targeting;
  lastDevicePreview: DeviceType;
  status: ExperienceStatus;

  quickPreviewUrl?: string;
  quickPreviewPlacement?: PlacementKind;
  quickPreviewDevice?: DeviceType;

  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  pendingUpdatingAt?: string;
  publishedAt?: string;
  metricsStartAt?: string;
  archivedAt?: string;
  endDate?: string;
  isScheduleActive?: boolean;
  isPostPurchase?: boolean;

  experienceCreationVersion?: number;

  labels: string[];
  beforeImages: string[];
  afterImages: string[];
}

export interface Schedule {
  type: ScheduleType;
  timeZone: TimeZone;
  weekDay?: string;
  utcStart?: number | string;
  utcEnd?: number | string;
  startMinute?: number;
  startHour?: number;
  startSecond?: number;
  endMinute?: number;
  endHour?: number;
  endSecond?: number;
}

export enum ScheduleType {
  SPECIFIC_DATE = `SPECIFIC_DATES`,
  EVERY_DAY_AT_A_CERTAIN_TIME = `DAILY`,
  EVERY_WEEK_ON_A_CERTAIN_DAY = `DAY_OF_WEEK`,
}

export interface TimeZone {
  label: string;
  offset: number;
}

export interface ExperienceVariant {
  id: string;
  name: string;
  chance: number;
  publishedChance?: number;
  changes: EditorDeclarativeBlock[];
  pageRegex: string;

  metrics?: MetricValue[];
  revenueGauss?: AbTestResult;
  conversionsBinomal?: AbTestResult;
}

export enum HtmlTransformKind {
  UNKNOWN = 0,
  REPLACE = 1,
  APPEND_AFTER = 2,
  APPEND_BEFORE = 3,
}

export enum EditorChangeKind {
  UNKNOWN = `UNKNOWN_CHANGE_KIND`,
  EDIT_COMPOUND = `EDIT_COMPOUND`,
  HIDE_COMPONENT = `HIDE_COMPONENT`,
  GLOBAL_CSS = `GLOBAL_CSS`,
  GLOBAL_JS = `GLOBAL_JS`,
  NEW_COMPONENT = `NEW_COMPONENT`,
  MOVE_COMPONENT = `MOVE_COMPONENT`,
  FAKE_CLICK = `FAKE_CLICK`,
  VISUAL_EDIT = `VISUAL_EDIT`,
}

export interface EditorDeclarativeBlock {
  block: DeclarativeBlock;
  editorId: string;
  editorKind: EditorChangeKind;
  editorSelector: string;
  widgetProps?: CatalogWidgetProps;
  checkoutWidgetManifest?: CatalogCheckoutWidget;
  checkoutMountPointInfo?: CheckoutNewComponent;
  initialHtml?: string;
  targetPlacement?: Placement;
  targetDevice?: TargetingDeviceType;
  visualEditSpecs?: Record<string, CustomizationSpec>;
  initialVisualEditChange?: Record<string, CustomizationSpec>;
  pageRedirectOpts?: PageRedirectOptions;
  hint?: string;
  isDisabled?: boolean;
  isInCart?: boolean;
}

export function getWidgetId(
  change: EditorDeclarativeBlock,
): string | undefined {
  return (change?.block?.value as WidgetChange)?.widgetId;
}

export interface KlavioMetadata {
  // https://developers.klaviyo.com/en/v1-2/docs/track-klaviyo-form-activity-using-javascript
  type: KlavioEvent;
  step?: KlavioStep;
}

export type EventMetadata = KlavioMetadata;

export type EventName = 'klaviyoForms';

export interface Trigger {
  kind: TriggerKind;
  selector?: string;
  timeoutMillis?: number;
  code?: string;
  retries?: number;
  event?: {
    name: EventName;
    meta: EventMetadata;
    matchCode: string;
  };
  elementEvent?: {
    name: string;
    once: boolean;
  };
}

export enum KlavioStep {
  EMAIL = `EMAIL`,
  EMAIL_SMS = `EMAIL_SMS`,
  SMS = `SMS`,
}

export enum KlavioEvent {
  NOT_SET = `NOT_SET`,
  OPEN = `open`,
  CLOSE = `close`,
  SUBMIT = `submit`,
  OPEN_EMBED = `openEmbed`,
  STEP_SUBMIT = `stepSubmit`,
  REDIRECT_TO_URL = `redirectToUrl`,
}

export enum TriggerKind {
  UNKNOWN_TRIGGER = `UNKNOWN_TRIGGER`,
  PAGE_LOAD = `PAGE_LOAD`,
  TIMEOUT = `TIMEOUT`,
  INACTIVITY = `INACTIVITY`,
  ELEMENT_APPEARING = `ELEMENT_APPEARING`,
  EXIT_INTENT = `EXIT_INTENT`,
  KLAVIO_EVENT = `KLAVIO_EVENT`,
  ELEMENT_CLICKED = `ELEMENT_CLICKED`,
  ELEMENT_HOVER = `ELEMENT_HOVER`,
  JAVASCRIPT_FUNCTION = `JAVASCRIPT_FUNCTION`,
}

export enum YesNo {
  UNSET = `UNSET`,
  YES = `YES`,
  NO = `NO`,
}

export interface Targeting {
  segments: string[];
  device: TargetingDeviceType;
  placement: Placement;
  other?: QBItemSelection[];
  facebook?: FacebookTargeting;
  sendGAEvents?: YesNo;
  userDefinedAudienceId?: string | undefined;
}

export enum Segment {
  UNKNOWN_SEGMENT = `UNKNOWN_SEGMENT`,
  ABANDONED_CARTS = `ABANDONED_CARTS`,
  VIEWED_PRODUCTS = `VIEWED_PRODUCTS`,
  PURCHASED_RECENTLY = `PURCHASED_RECENTLY`,
  PURCHASED_LAST_WEEK = `PURCHASED_LAST_WEEK`,
  NEVER_PURCHASED = `NEVER_PURCHASED`,
  HIGH_SPENDERS = `HIGH_SPENDERS`,
  COUPON_LOVERS = `COUPON_LOVERS`,
  ONE_TIMERS = `ONE_TIMERS`,
  FIRST_SESSION = `FIRST_SESSION`,
  RETURNING_VISITORS = `RETURNING_VISITORS`,
  SUBSEQUENT_SESSIONS = `SUBSEQUENT_SESSIONS`,
  CUSTOMERS = `CUSTOMERS`,
  GUESTS = `GUESTS`,
  FACEBOOK = `FACEBOOK`,
  OTHER = `OTHER`,
}

export interface Placement {
  kind: PlacementKind;
  other?: QBItemSelection[];
}

export enum PlacementKind {
  HOMEPAGE = `HOMEPAGE`,
  ALL_CATEGORIES = `ALL_CATEGORIES`,
  ALL_PRODUCTS = `ALL_PRODUCTS`,
  CHECKOUT = `CHECKOUT`,
  CART = `CART`,
  MINI_CART = `MINI_CART`,
  ALL_PAGES = `ALL_PAGES`,
  OTHER = `OTHER`,
}

export enum TargetingDeviceType {
  ALL_DEVICES = `ALL_DEVICES`,
  MOBILE = `MOBILE`,
  TABLET = `TABLET`,
  DESKTOP = `DESKTOP`,
}

export function toTargetingDeviceType(deviceType: DeviceType) {
  return deviceType === DeviceType.Desktop
    ? TargetingDeviceType.DESKTOP
    : TargetingDeviceType.MOBILE;
}

export enum ExperienceStatus {
  UNKNOWN_STATUS = `UNKNOWN`,
  DRAFT = `DRAFT`,
  PUBLISHED = `PUBLISHED`,
  ARCHIVED = `ARCHIVED`,
  PAUSED = `PAUSED`,
  SCHEDULED = `SCHEDULED`,
}

export enum ExperienceFilter {
  UNKNOWN_STATUS = `UNKNOWN_STATUS`,
  DRAFT = `DRAFT`,
  PUBLISHED = `PUBLISHED`,
  ARCHIVED = `ARCHIVED`,
  PAUSED = `PAUSED`,
  AB_TEST = `AB_TEST`,
  ONE_HUNDRED_PERCENT = `ONE_HUNDRED_PERCENT`,
}

export interface ExperienceSort {
  option: ExperienceSortOption;
  direction: ExperienceSortDirection;
}

export enum ExperienceSortOption {
  __NA = `__NA`,
  BY_CREATION_DATE = `BY_CREATION_DATE`,
  BY_PUBLISH_DATE = `BY_PUBLISH_DATE`,
  BY_NAME = `BY_NAME`,
  BY_UPDATE_DATE = `BY_UPDATE_DATE`,
}

export enum ExperienceSortDirection {
  ___NA = 1,
  ASC = 1,
  DESC = -1,
}

export const sortByCreationDateAsc: ExperienceSort = {
  option: ExperienceSortOption.BY_CREATION_DATE,
  direction: ExperienceSortDirection.ASC,
};

export const sortByCreationDateDesc: ExperienceSort = {
  option: ExperienceSortOption.BY_CREATION_DATE,
  direction: ExperienceSortDirection.DESC,
};

export const sortByPublishDateAsc: ExperienceSort = {
  option: ExperienceSortOption.BY_PUBLISH_DATE,
  direction: ExperienceSortDirection.ASC,
};

export const sortByPublishDateDesc: ExperienceSort = {
  option: ExperienceSortOption.BY_PUBLISH_DATE,
  direction: ExperienceSortDirection.DESC,
};

export const sortByNameDateAsc: ExperienceSort = {
  option: ExperienceSortOption.BY_NAME,
  direction: ExperienceSortDirection.ASC,
};

export const sortByNameDateDesc: ExperienceSort = {
  option: ExperienceSortOption.BY_NAME,
  direction: ExperienceSortDirection.DESC,
};

export const sortByLastUpdatedAsc: ExperienceSort = {
  option: ExperienceSortOption.BY_UPDATE_DATE,
  direction: ExperienceSortDirection.ASC,
};

export const sortByLastUpdatedDesc: ExperienceSort = {
  option: ExperienceSortOption.BY_UPDATE_DATE,
  direction: ExperienceSortDirection.DESC,
};

export interface SharePreviewReq {
  email: string;
  sharedBy: string;
  options: {
    experienceId: string;
    variantId: string;
    quickPreviewUrl: string;
  };
}

export interface VariantPreview {
  id: string;
  name: string;
  link: string;
}

function defaultChanges(isPostPurchase: boolean) {
  return isPostPurchase
    ? [
        {
          block: {
            kind: `appendJs`,
            selector: `div`,
            value: `(function () {1})();`,
          },
          editorId: `DONTCARE`,
          editorKind: EditorChangeKind.GLOBAL_JS,
          targetDevice: TargetingDeviceType.DESKTOP,
          targetPlacement: {
            kind: PlacementKind.HOMEPAGE,
          },
        } as EditorDeclarativeBlock,
      ]
    : [];
}
