import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useFacebookApi } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/api';
import { Spinner } from '@/Spinner';
import { VSpace } from '@/components/spacing';
import {
  FacebookAudienceContext,
  FBView,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { getFBQuery } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

export const AudienceValidation: FC = () => {
  const { loading, verify } = useFacebookApi(true);
  const { targeting, revalidateBit, setStep, setValidationLoading } =
    useContext(FacebookAudienceContext);

  useEffect(() => setValidationLoading(loading), [loading]);
  useEffect(() => {
    (async () => {
      const response = await verify(getFBQuery(targeting.current));
      if (response.notLoggedIn) {
        setStep(FBView.CONNECT);
      }
      const invalid = !!response?.invalid;
      if (!invalid) {
        setStep(FBView.SUCCESS);
      }
    })();
  }, [revalidateBit]);
  if (loading) {
    return (
      <SpinnerContainer>
        <VSpace value={10} />
        <Spinner size={80} />
        <p>validating your selection...</p>
      </SpinnerContainer>
    );
  }
  return (
    <Container>
      <Step>
        <Num>1</Num> <span>Go To Meta Ads Manager</span>
      </Step>
      <Step>
        <Num>2</Num> <span>Edit relevant ad</span>
      </Step>
      <StepColumn>
        <FlexRow>
          <Num>3</Num>
          <span>
            Go to Tracking {`>`} URL Parameters and add the following code
          </span>
        </FlexRow>
        <VSpace />
        <CodeWrapper>
          {`utm_campaign={{campaign.id}}&utm_term={{adset.id}}`}
          <br />
          {`&utm_content={{ad.id}}`}
          <CopyBtn
            type="button"
            onClick={() =>
              navigator.clipboard.writeText(
                `utm_campaign={{campaign.id}}&utm_term={{adset.id}}&utm_content={{ad.id}}`,
              )
            }
          >
            COPY
          </CopyBtn>
        </CodeWrapper>
        <VSpace />
        <a
          href="https://www.facebook.com/business/help/2360940870872492"
          target="_blank"
          rel="noreferrer"
        >
          * Meta help article
        </a>
        <ArrowImageWrap>
          <StaticImage
            src="./arrow.svg"
            alt="connect"
            placeholder="none"
            loading="eager"
            quality={100}
            width={60}
          />
        </ArrowImageWrap>
        <FacebookImageWrap>
          <StaticImage
            src="./url_params.png"
            alt="connect"
            placeholder="none"
            loading="eager"
            quality={100}
            height={215}
          />
        </FacebookImageWrap>
      </StepColumn>
    </Container>
  );
};

const ArrowImageWrap = styled.div`
  position: absolute;
  left: 65%;
  top: 70%;
`;

const FacebookImageWrap = styled.div`
  position: absolute;
  left: 75%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const CopyBtn = styled.button`
  margin-left: 2rem;
  border-radius: 20px;
  color: #3f4a53;
  background-color: #d8d8d8;
  font-family: 'JetBrains Mono', serif;
  font-weight: 600;
  padding: 1rem;
  user-select: none;

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }

  :active {
    opacity: 1;
    cursor: default;
  }
`;

const CodeWrapper = styled.p`
  text-align: start;
  user-select: none;
  padding: 3rem 2rem;
  color: #6d757b;
  border-radius: 10px;
  background-color: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Step = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: #f4f4f4;
  padding: 2rem;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 2.4rem;

  span {
    color: #939699;
  }
`;

const StepColumn = styled(Step)`
  && {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
`;

const Num = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #939699;
  background-color: #dbdfe2;
  font-family: 'JetBrains Mono', serif;
  font-weight: 500;
  font-size: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.4rem;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
