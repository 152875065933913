import { useLocalStorage } from 'react-use';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  CheckoutType,
  FeatureBit,
  isFeatureBitEnabled,
  StoreAnalysis,
  StoreDetails,
  useAuthApi,
} from '@/webapi/use-auth-api';
import { nav, setHttpsPrefix } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { UserStatsKind, useUserStats } from '@/webapi/use-user-stats';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import { StoreStatsKind, useStoreStats } from '@/webapi/use-store-stats';
import {
  Subscription,
  useShopifySubscriptionDetails,
} from '@/features/shop-settings/useShopifySubscriptionDetails';
import { maybe } from '@/features/details/utils';

export const ACCOUNT_DETAILS_KEY = `loomi_account`;

export const AccountContext = React.createContext({} as AccountContextProps);

export function createAccountContext(): AccountContextProps {
  const [account, _setAccount] = useLocalStorage(
    ACCOUNT_DETAILS_KEY,
    emptyAccountDetails(),
  );
  const accountRef = useRef(account);

  const { isAppEmbedEnabled, loading: isAppEmbedStatusLoading } = useShopifyApi(
    true,
    account?.store?.alias,
  );

  const [appEmbedStatus, setAppEmbedStatus] = useState<AppEmbedStatus>({
    isInit: false,
    isEnabled: false,
    deepLink: ``,
  });

  const [isAppEmbedModalVisible, setIsAppEmbedModalVisible] =
    useState<boolean>(false);

  function setAccount(account: any) {
    _setAccount(account);
    accountRef.current = account;
  }

  const { getUserContext, logout: deleteCookie, loading } = useAuthApi();

  const { trackIfGoalNotReached, isGoalReached, isDuplicate } =
    useUserStats(account);

  const {
    trackIfGoalNotReached: trackIfStoreGoalNotReached,
    isGoalReached: isStoreGoalReached,
    isDuplicate: isStoreGoalIsDuplicate,
  } = useStoreStats(account);

  const { subscription } = useShopifySubscriptionDetails(
    maybe(() => account.store.alias),
  );

  const isTrialInactive = useMemo(() => {
    const currentDate = new Date();
    const trialEnds = maybe(() => new Date(subscription.trialEnds));
    return currentDate >= trialEnds;
  }, [subscription]);

  const fetchUserContext = async () => {
    const resp = await getUserContext();
    if (resp?.domain) {
      resp.domain = setHttpsPrefix(resp.domain);
      if (
        resp?.featureBits?.[`enable-test-store-publish`] &&
        !!resp?.isTestStore
      ) {
        resp.isTestStore = false;
      }
      setAccount({ isLoggedIn: true, store: resp });
      window[`vsly_fbs`] = resp.featureBits;
      setParallelFlagsFlag();
    }
    return resp;
  };

  function setParallelFlagsFlag() {
    window.parallelTabs = true;
    window.nativeLocalStorage.setItem(`parallelTabs`, `${true}`);
  }

  const logout = async () => {
    await deleteCookie();
    localStorage.clear();
    setAccount(emptyAccountDetails());
    window.location.replace(`https://${window.location.hostname}/`);
  };

  const isLoggedIn = () =>
    accountRef.current.isLoggedIn &&
    !!localStorage.getItem(ACCOUNT_DETAILS_KEY);

  const invalidSubscription = () => isInvalidSubscription(accountRef.current);

  useEffect(() => {
    if (accountRef.current?.store?.alias) {
      console.debug(
        `vsly`,
        `checking for app embed status`,
        accountRef.current?.store?.alias,
      );
      refreshAppEmbedStatus(accountRef.current?.store?.alias).then();
    } else {
      setAppEmbedStatus({ isInit: false, isEnabled: false, deepLink: `` });
    }
  }, [accountRef?.current?.store?.alias]);

  useEffect(() => {
    console.debug(
      `vsly`,
      `app embed status changed`,
      account?.store?.alias,
      appEmbedStatus,
    );
  }, [appEmbedStatus]);

  const refreshAppEmbedStatus = async (alias: string) => {
    const resp = await isAppEmbedEnabled();
    if (resp && accountRef?.current?.store?.alias === alias) {
      setAppEmbedStatus({
        isInit: true,
        isEnabled: resp.isAppBlockEnabled,
        deepLink: resp.deepLink,
        legacy_sdk_installed: !!resp?.legacy_sdk_installed,
      });
    } else {
      setAppEmbedStatus({ isInit: false, isEnabled: false, deepLink: `` });
    }
    return Promise.resolve();
  };

  const showAppEmbedModalIfNeeded = () => {
    if (isAppEmbedRequired()) {
      setIsAppEmbedModalVisible(true);
      return true;
    }
    return false;
  };

  const isAppEmbedRequired = useCallback(
    () =>
      appEmbedStatus.isInit &&
      appEmbedStatus.isEnabled === false &&
      !account?.store?.isTestStore,
    [appEmbedStatus, account],
  );

  const shouldShowBookDemo = useMemo(() => {
    if (account?.store?.isTestStore === true) {
      return false;
    }
    return (account?.store?.fastSyncOrdersCount || 0) > 3000;
  }, [account]);

  const setSdkInjectStatus = (b: boolean) => {
    setAppEmbedStatus((prevState) => ({
      ...prevState,
      isEnabled: b,
      legacy_sdk_installed: b,
    }));
  };

  const checkoutType = useMemo(() => {
    if (
      account?.store?.storeAnalysis?.checkoutType ===
      CheckoutType.SHOPIFY_LEGACY_CHECKOUT
    ) {
      return CheckoutType.SHOPIFY_LEGACY_CHECKOUT;
    }
    return CheckoutType.SHOPIFY_CHECKOUT_EXTENSIBILITY;
  }, [account]);

  const storeAnalysis = useMemo(() => account?.store?.storeAnalysis, [account]);

  const isCartSelectorExist = useMemo(
    () =>
      typeof storeAnalysis?.cartRootSelector === `string` &&
      storeAnalysis?.cartRootSelector.trim() !== ``,
    [storeAnalysis],
  );

  return {
    isLoggedIn,
    invalidSubscription,
    account,
    fetchUserContext,
    logout,
    loading,
    trackIfGoalNotReached,
    isGoalReached,
    isDuplicateGoal: isDuplicate,
    trackIfStoreGoalNotReached,
    isStoreGoalReached,
    isStoreGoalIsDuplicate,
    appEmbedStatus,
    isAppEmbedStatusLoading,
    refreshAppEmbedStatus,
    setIsAppEmbedModalVisible,
    isAppEmbedModalVisible,
    showAppEmbedModalIfNeeded,
    isAppEmbedRequired,
    shouldShowBookDemo,
    setSdkInjectStatus,
    isTrialInactive,
    subscription,
    checkoutType,
    storeAnalysis,
    isCartSelectorExist,
  };
}

export function useFeatureBit(fb: FeatureBit, fallback = false): boolean {
  const { account } = useContext(AccountContext);
  return isFeatureBitEnabled(account?.store?.featureBits, fb, fallback);
}

export function useFetchUserContext(
  accountCtx: AccountContextProps,
  pathName?: string | undefined,
) {
  const [, setAliasQp] = useQueryParam(`al`, StringParam);

  useEffect(() => {
    (async () => {
      try {
        if (isPrivatePath(pathName)) {
          const details = await accountCtx.fetchUserContext();
          if (window?.parallelTabs && !!details?.alias) {
            setAliasQp(details.alias);
          }
          if (!isAccountSelect(pathName) && accountCtx.invalidSubscription()) {
            await nav(Pages.PLEASE_REINSTALL);
          }
        }
      } catch (e) {
        console.warn(`useFetchUserContext: unexpected failure`, e);
      }
    })();
  }, [pathName]);
}

function isAccountSelect(pathname: string) {
  return pathname?.startsWith(`/accounts`);
}

export function isPrivatePath(pathname: string): boolean {
  if (pathname === `/`) return false;

  return [
    `/public`,
    `/welcome`,
    `/signup`,
    `/await-email`,
    `/login`,
    `/404`,
    `/pricing`,
    `/product-demo`,
  ]
    ?.map((x) => !pathname?.startsWith(x))
    ?.reduce((prev, curr) => prev && curr);
}

export interface AccountContextProps {
  isLoggedIn: () => boolean;
  invalidSubscription: () => boolean;
  account: AccountDetails;
  fetchUserContext: () => Promise<StoreDetails>;
  logout: () => Promise<void>;
  loading: boolean;
  trackIfGoalNotReached: (
    kind: UserStatsKind,
    ...dedupKey: string[]
  ) => Promise<boolean>;
  isGoalReached: (kind: UserStatsKind) => boolean;
  isDuplicateGoal: (dedupKey: string) => boolean;
  trackIfStoreGoalNotReached: (
    kind: StoreStatsKind,
    ...dedupKey: string[]
  ) => Promise<boolean>;
  isStoreGoalReached: (kind: StoreStatsKind) => boolean;
  isStoreGoalIsDuplicate: (dedupKey: string) => boolean;
  appEmbedStatus?: AppEmbedStatus;
  isAppEmbedStatusLoading: boolean;
  refreshAppEmbedStatus: (alias: string) => Promise<void>;
  setIsAppEmbedModalVisible: (isVisible: boolean) => void;
  isAppEmbedModalVisible: boolean;
  showAppEmbedModalIfNeeded: () => boolean;
  isAppEmbedRequired: () => boolean;
  shouldShowBookDemo: boolean;
  setSdkInjectStatus: (b: boolean) => void;
  isTrialInactive: boolean;
  subscription: Subscription;
  checkoutType: CheckoutType;
  storeAnalysis: StoreAnalysis;
  isCartSelectorExist: boolean;
}

export interface AccountDetails {
  isLoggedIn: boolean;
  store: StoreDetails;
}

export interface AppEmbedStatus {
  isInit: boolean;
  isEnabled: boolean;
  legacy_sdk_installed?: boolean;
  deepLink: string;
  alias?: string;
}

const emptyAccountDetails = (): AccountDetails => ({
  isLoggedIn: false,
  store: undefined,
});

export function isInvalidSubscription(account: AccountDetails) {
  const subscription = account?.store?.subscription;
  const isUninstalled = account?.store?.isUninstalled;
  const notActive = !!subscription && subscription?.status !== `ACTIVE`;
  return isUninstalled || notActive;
}
