/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import {
  EditorDeclarativeBlock,
  Experience,
  ExperienceInfoResponse,
  ExperienceStatus,
  ExperienceVariant,
  Placement,
  PlacementKind,
  Schedule,
  TargetingDeviceType,
  toTargetingDeviceType,
  Trigger,
  YesNo,
} from '@/webapi/use-experience-api';
import {
  FacebookTargeting,
  QBItemSelection,
  QBType,
} from '@/components/query-builder/models';
import { MetricKind } from '@/webapi/models';
import { DeviceType } from '@/utils/definitions';
import { useStateWithHistory } from '@/utils/use-complex-state';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import { UPSELLS_APP_ID } from '@/webapi/use-catalog-api';
import {
  PostPurchaseOffer,
  PostPurchaseProps,
} from '@/features/editor/widgets/post-purchase/props';
import { WidgetChange } from '@/pkg/sdk';
import { getRandomInt } from '@/utils/types';
import { generateUUID } from '@/webapi/common';
import { cleanupRecommendationRequest } from '@/features/editor/widgets/custom-widget/env';

export interface ExperienceStateHook {
  renameVariant: (id: string, newName: string) => void;
  selectActiveVariant: (id: string) => void;
  revertMultivariantChanges: (e: Experience) => void;
  deleteVariant: (id: string) => void;
  addEmptyVariant: () => { id: string };
  cloneVariant: (name: string) => { id: string };
  setPostPurchaseProps: (props?: PostPurchaseProps) => void;
  currentExperience: Experience;
  getCurrentVariant: () => ExperienceVariant;
  currentVariantIdx: number;
  setCurrentVariantIdx: (idx: number) => void;
  toggleGaEvent: (toggle: boolean) => void;
  upsertEditorChange: (
    change: EditorDeclarativeBlock,
    toHead?: boolean,
    changeIsDuplicate?: boolean,
  ) => void;
  findEditorChange: (id: string) => EditorDeclarativeBlock;
  findAllCheckoutExtensibilityChanges: () => EditorDeclarativeBlock[];
  removeEditorChange: (change: EditorDeclarativeBlock) => void;
  changeAllocation: (variant: ExperienceVariant) => void;
  scheduleExperience: (schedule: Schedule) => void;
  setTargetingDevice: (td: TargetingDeviceType) => void;
  setTargetingAudience: (
    segments: string[],
    other: QBItemSelection[],
    facebook?: FacebookTargeting,
    userDefinedAudience?: string,
  ) => void;
  setTargetingPlacement: (placement: Placement, bypassRules?: boolean) => void;
  setTargetingTrigger: (trigger: Trigger) => void;
  setExperienceName: (name: string, desc?: string | null) => void;
  setExperienceInfo: (info: ExperienceInfoResponse) => void;
  selectTheme: (id: number) => void;
  setTargetingGoals: (goals: MetricKind[]) => void;
  setPrimaryGoal: (goals: MetricKind) => void;
  setChangeTargetPlacement: (
    editorId: string,
    placement: PlacementKind,
  ) => void;
  setQuickPreviewInfo: (info: {
    url?: string;
    placement?: PlacementKind;
    device?: DeviceType;
  }) => void;
  setAfterUpsert: (exp: Experience) => Experience;
  setLastPreviewDevice: (d: DeviceType) => void;
  lastPreviewDevice?: DeviceType;

  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;

  latestChangeEdit: ChangeEdit | undefined;

  getPageRedirectChange: () => EditorDeclarativeBlock | undefined;

  hasPendingChanges: boolean;
}

export function useExperienceState(
  experience: Experience,
  apps: CatalogApp[],
): ExperienceStateHook {
  const {
    state: currentExperience,
    redo,
    undo,
    canRedo,
    canUndo,
    produceDispatch: setCurrentExperience,
  } = useStateWithHistory<Experience>(
    experience,
    `EXPERIENCE_${experience.id}_HISTORY`,
  );

  const [latestChangeEdit, setLatestChangeEdit] = useState<
    ChangeEdit | undefined
  >(undefined);

  const [currentVariantIdx, setCurrentVariantIdx] = useState(0);
  useEffect(() => {
    const currIndex = currentExperience.variants.findIndex(
      (v) => v.id === currentExperience.activeVariantId,
    );
    setCurrentVariantIdx(currIndex === -1 ? 0 : currIndex);
  }, [currentExperience?.activeVariantId]);

  const hasPendingChanges = useMemo(() => {
    if (
      currentExperience.status === ExperienceStatus.PUBLISHED &&
      currentExperience?.updatedAt &&
      currentExperience?.publishedAt
    ) {
      const lastPendingChanges =
        currentExperience?.pendingUpdatingAt || currentExperience?.updatedAt;

      const updated = Math.floor(new Date(lastPendingChanges).getTime() / 1000);
      const published = Math.floor(
        new Date(currentExperience?.publishedAt).getTime() / 1000,
      );

      return updated > published;
    }
    return false;
  }, [
    currentExperience?.pendingUpdatingAt,
    currentExperience?.updatedAt,
    currentExperience?.publishedAt,
    currentExperience?.status,
  ]);

  const getCurrentVariant = (): ExperienceVariant => {
    const variant = currentExperience?.variants?.[currentVariantIdx];
    if (variant) {
      return variant;
    }
    setCurrentVariantIdx(0);
    return currentExperience?.variants[0];
  };

  const setPostPurchaseProps = (props?: PostPurchaseProps) => {
    setCurrentExperience((draft) => {
      draft.postPurchaseProps = { ...props };
      draft.postPurchaseProps.offers = draft.postPurchaseProps.offers.map(
        (offer) => {
          const ret = {
            product: undefined,
            loadingEnv: offer.loadingEnv,

            discountPercentage: offer.discountPercentage,
            loadingStrategy: offer.loadingStrategy,
          } as PostPurchaseOffer;
          if (offer.loadingEnv) {
            ret.cleanLoadingEnv = cleanupRecommendationRequest({
              settings: { loading: { loadingEnv: offer.loadingEnv } },
            } as any);
          }
          return ret;
        },
      );
      draft.postPurchaseProps.randSeed = getRandomInt(0, 1000000);
    });
  };

  const getPageRedirectChange = (): EditorDeclarativeBlock | undefined =>
    currentExperience.variants[currentVariantIdx]?.changes?.find(
      (ch) => ch.block.kind === `pageRedirect`,
    );

  const renameVariant = (id: string, newName: string) => {
    setCurrentExperience((draft) => {
      draft.variants.forEach((value, index) => {
        if (value.id === id) {
          draft.variants[index].name = newName;
        }
      });
      return draft;
    });
  };

  const deleteVariant = (id: string) => {
    if (currentExperience.variants.length === 1) {
      return;
    }
    setCurrentExperience((draft) => {
      draft.variants = draft.variants.filter((v) => v.id !== id);
      if (draft.activeVariantId === id) {
        draft.activeVariantId = draft.variants[0].id;
      }
      return draft;
    });
  };

  const selectActiveVariant = (id: string) => {
    setCurrentExperience((draft) => {
      draft.activeVariantId = id;
      return draft;
    });
  };
  const upsertEditorChange = (
    pchange: EditorDeclarativeBlock,
    toHead = false,
    changeIsDuplicate = false,
  ) => {
    const exists = findEditorChange(pchange?.editorId);
    const change = produce(pchange, (draft) => {
      draft.targetPlacement = exists?.targetPlacement
        ? exists?.targetPlacement
        : resolveChangeTargetPlacement(
            currentExperience?.targeting?.placement?.kind,
            {
              kind: currentExperience.quickPreviewPlacement,
              other: currentExperience?.targeting?.other,
            },
            currentExperience.quickPreviewUrl,
            draft,
            apps,
          );
      if (changeIsDuplicate && pchange?.targetPlacement) {
        draft.targetPlacement = pchange.targetPlacement;
      }
      if (!draft.targetDevice) {
        draft.targetDevice = exists?.targetDevice
          ? exists?.targetDevice
          : toTargetingDeviceType(currentExperience.quickPreviewDevice);
      }
    });

    const v = getCurrentVariant();
    if (v) {
      setCurrentExperience((draft) => {
        let found = false;
        draft.variants[currentVariantIdx].changes = v?.changes?.map((ch) => {
          if (ch.editorId === change.editorId) {
            found = true;
            return change;
          }
          return ch;
        });

        if (!found) {
          if (!draft.variants[currentVariantIdx].changes) {
            draft.variants[currentVariantIdx].changes = [];
          }
          if (toHead) {
            draft.variants[currentVariantIdx].changes.unshift(change);
          } else {
            draft.variants[currentVariantIdx].changes.push(change);
          }
        }
      });

      setLatestChangeEdit({
        change,
        variant: v,
        experienceId: experience.id,
        experienceName: experience.name,
      });
    }
  };

  const removeEditorChange = (change: EditorDeclarativeBlock) => {
    setCurrentExperience((draft) => {
      draft.variants[currentVariantIdx].changes = draft.variants[
        currentVariantIdx
      ]?.changes?.filter((ch) => ch.editorId !== change.editorId);
    });
  };

  const findEditorChange = useCallback(
    (id: string): EditorDeclarativeBlock | undefined => {
      const found = currentExperience.variants[
        currentVariantIdx
      ]?.changes?.filter((ch) => ch.editorId === id);
      if (found && found.length > 0) {
        return found[0];
      }
      return undefined;
    },
    [currentExperience, currentVariantIdx],
  );

  const changeAllocation = (variant: ExperienceVariant) => {
    const idx = currentExperience.variants
      .map((v, idx) => {
        if (v.id === variant.id) {
          return idx;
        }
        return -1;
      })
      .filter((idx) => idx >= 0);

    if (idx && idx.length > 0) {
      setCurrentExperience((draft) => {
        draft.variants[idx[0]] = variant;
      });
    }
  };

  const scheduleExperience = (schedule: Schedule) => {
    setCurrentExperience((draft) => {
      draft.schedule = schedule;
    });
  };

  const toggleGaEvent = (isSelected: boolean) => {
    setCurrentExperience((draft) => {
      draft.targeting.sendGAEvents = isSelected ? YesNo.YES : YesNo.NO;
    });
  };

  const setTargetingDevice = (device: TargetingDeviceType) => {
    setCurrentExperience((draft) => {
      draft.targeting.device = device;
    });
  };

  const setLastPreviewDevice = (device: DeviceType) => {
    setCurrentExperience((draft) => {
      draft.lastDevicePreview = device;
    });
  };

  const setTargetingAudience = (
    segments: string[],
    other: QBItemSelection[],
    facebook: FacebookTargeting | undefined = undefined,
    userDefinedAudienceId = ``,
  ) => {
    setCurrentExperience((draft) => {
      draft.targeting.segments = segments?.filter((f) => typeof f === `string`);
      draft.targeting.other = other;
      draft.targeting.facebook = facebook || null;
      draft.targeting.userDefinedAudienceId = userDefinedAudienceId;
    });
  };

  const setTargetingPlacement = (
    placement: Placement,
    bypassRules?: boolean,
  ) => {
    setCurrentExperience((draft) => {
      draft.targeting.placement = placement;
      if (placement?.kind === PlacementKind.OTHER && !bypassRules) {
        draft?.variants?.[currentVariantIdx]?.changes?.map((change) => {
          if (change.targetPlacement?.kind === PlacementKind.OTHER) {
            change.targetPlacement = resolveChangeTargetPlacement(
              placement?.kind,
              placement,
              currentExperience.quickPreviewUrl,
            );
          }
          return change;
        });
      }
    });
  };

  const setTargetingGoals = (goals: MetricKind[]) => {
    setCurrentExperience((draft) => {
      draft.goals = goals;
    });
  };

  const setPrimaryGoal = (goal: MetricKind) => {
    setCurrentExperience((draft) => {
      draft.primaryGoal = goal;
    });
  };

  const setTargetingTrigger = (trigger: Trigger) => {
    setCurrentExperience((draft) => {
      draft.trigger = trigger;
    });
  };

  const setExperienceName = (name: string, descr: string) => {
    if (name !== `` || descr !== ``) {
      setCurrentExperience((draft) => {
        if (name !== ``) {
          draft.name = name;
        }
        if (descr === null) {
          draft.description = ``;
        }
        if (descr) {
          draft.description = descr;
        }
      });
    }
  };

  const setExperienceInfo = (info: ExperienceInfoResponse) => {
    setCurrentExperience((draft) => {
      draft.name = info.name;
      draft.description = info.description;
      draft.labels = info.labels
        ?.filter((l) => l.selected)
        ?.map((l) => l.label);
      draft.beforeImages = info.beforeImages;
      draft.afterImages = info.afterImages;
    });
  };

  const selectTheme = (themeId: number) => {
    setCurrentExperience((draft) => {
      draft.themeId = themeId;
    });
  };

  const setChangeTargetPlacement = (
    editorId: string,
    placement: PlacementKind,
  ): void => {
    setCurrentExperience((draft) => {
      draft.variants[currentVariantIdx].changes.map((ch) => {
        if (ch.editorId === editorId) {
          ch.targetPlacement = {
            kind: placement,
            other: currentExperience?.targeting?.placement?.other,
          };
        }
        return ch;
      });
    });
  };

  const setQuickPreviewInfo = (info: {
    url?: string;
    placement?: PlacementKind;
    device?: DeviceType;
  }): void => {
    setCurrentExperience((draft) => {
      if (info.url) draft.quickPreviewUrl = info.url;
      if (info.placement) draft.quickPreviewPlacement = info.placement;
      if (info.device) draft.quickPreviewDevice = info.device;
    });
  };

  const setAfterUpsert = (exp: Experience) => {
    const name = exp?.name ? exp.name : currentExperience.name;
    const description =
      exp?.description || currentExperience?.description || ``;
    const updated = produce(currentExperience, (draft) => {
      draft.name = name;
      draft.description = description;
      draft.updatedAt = exp?.updatedAt;
      draft.publishedAt = exp?.publishedAt;
      draft.pendingUpdatingAt = exp?.pendingUpdatingAt;
    });
    setCurrentExperience(
      (draft) => {
        draft.name = name;
        draft.description = description;
        draft.updatedAt = updated.updatedAt;
        draft.publishedAt = updated.publishedAt;
        draft.pendingUpdatingAt = updated.pendingUpdatingAt;
      },
      false,
      true,
    );

    return updated;
  };

  const addEmptyVariant = () => {
    const newVariantId = generateUUID().replace(`-`, ``);
    setCurrentExperience(
      (draft) => {
        draft.activeVariantId = newVariantId;
        draft.variants = [
          ...draft.variants,
          {
            id: newVariantId,
            name: `Variation ${draft.variants.length + 1}`,
            changes: [],
            chance: 0,
          } as ExperienceVariant,
        ];
        return draft;
      },
      false,
      true,
    );
    return { id: newVariantId };
  };

  const cloneVariant = (variantId: string) => {
    const newVariantId = generateUUID().replace(`-`, ``);
    setCurrentExperience(
      (draft) => {
        const target = draft.variants.find((v) => v.id === variantId);
        draft.activeVariantId = newVariantId;
        draft.variants = [
          ...draft.variants,
          {
            id: newVariantId,
            name: `Copy of ${target.name}`,
            changes: JSON.parse(JSON.stringify(target?.changes || [])),
            chance: 0,
          } as ExperienceVariant,
        ];
        return draft;
      },
      false,
      true,
    );
    return { id: newVariantId };
  };

  const revertMultivariantChanges = (e: Experience) => {
    setCurrentExperience((draft) => {
      draft.activeVariantId = e.activeVariantId;
      draft.variants = e.variants;
      return draft;
    });
  };

  const findAllCheckoutExtensibilityChanges = (): EditorDeclarativeBlock[] =>
    getCurrentVariant()?.changes?.filter(
      (ch) => ch?.block?.kind === `checkoutWidget`,
    );

  return {
    revertMultivariantChanges,
    deleteVariant,
    renameVariant,
    selectActiveVariant,
    addEmptyVariant,
    cloneVariant,
    toggleGaEvent,
    currentExperience,
    getCurrentVariant,
    currentVariantIdx,
    setCurrentVariantIdx,
    upsertEditorChange,
    findEditorChange,
    removeEditorChange,
    changeAllocation,
    scheduleExperience,
    setTargetingDevice,
    setTargetingAudience,
    setTargetingPlacement,
    setTargetingTrigger,
    setExperienceName,
    selectTheme,
    setTargetingGoals,
    setPrimaryGoal,
    setChangeTargetPlacement,
    setQuickPreviewInfo,
    setAfterUpsert,
    setLastPreviewDevice,
    lastPreviewDevice: experience.lastDevicePreview,
    undo,
    redo,
    canRedo,
    canUndo,
    latestChangeEdit,
    setPostPurchaseProps,
    findAllCheckoutExtensibilityChanges,
    getPageRedirectChange,
    setExperienceInfo,
    hasPendingChanges,
  };
}

function singleUrlTargeting(url: string) {
  return [
    {
      qbProps: {
        kind: QBType.TEXT_VALUE,
        caption: `Page Url`,
        envKey: `Page $op "$value"`,
      },
      values: [
        {
          value: url,
          op: `is`,
        },
      ],
    },
  ];
}

function handleUpsellsPlacement(
  change: EditorDeclarativeBlock,
  expPlacementKind: PlacementKind,
  apps: CatalogApp[],
) {
  const widgetId = (change?.block?.value as WidgetChange)?.widgetId;
  if (
    widgetId &&
    [
      PlacementKind.ALL_PAGES,
      PlacementKind.MINI_CART,
      PlacementKind.OTHER,
    ].includes(expPlacementKind)
  ) {
    const app = apps.find(
      (app) =>
        app.widgets.find((widget) => widget?.id === widgetId) !== undefined,
    );
    if (app?.id === UPSELLS_APP_ID) {
      return {
        kind: PlacementKind.ALL_PAGES,
      };
    }
  }
  return undefined;
}

function resolveChangeTargetPlacement(
  expPlacementKind: PlacementKind,
  placement: Placement,
  previewUrl: string,
  change?: EditorDeclarativeBlock,
  apps?: CatalogApp[],
) {
  const upsellOverride = handleUpsellsPlacement(change, expPlacementKind, apps);
  if (upsellOverride) return upsellOverride;
  const targetPlacement: Placement = {
    kind: placement?.kind,
  };

  if (expPlacementKind === PlacementKind.ALL_PAGES) {
    targetPlacement.other = singleUrlTargeting(previewUrl);
  }

  if (
    expPlacementKind === PlacementKind.OTHER &&
    placement.kind === PlacementKind.OTHER
  ) {
    targetPlacement.other = placement?.other;
    // LVP-1381 - temp hack, remove and assign to the real formula
    targetPlacement.kind = PlacementKind.ALL_PAGES;
  }

  if (change?.checkoutWidgetManifest?.manifest?.id) {
    targetPlacement.kind = PlacementKind.CHECKOUT;
  }

  if (change?.block?.kind === `pageRedirect`) {
    targetPlacement.kind = PlacementKind.ALL_PAGES;
    change.targetDevice = TargetingDeviceType.ALL_DEVICES;
  }

  if (change.isInCart === true) {
    targetPlacement.kind = PlacementKind.ALL_PAGES;
  }

  return targetPlacement;
}

export interface ChangeEdit {
  experienceId: string;
  experienceName: string;
  change: EditorDeclarativeBlock;
  variant: ExperienceVariant;
}
