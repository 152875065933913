import { CachePolicies, useFetch } from 'use-http';
import useAsyncEffect from 'use-async-effect';
import { useSessionStorage } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { routes } from '@/webapi/routes';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import { AccountDetails } from '@/features/account-context';

const USER_STATS_STORAGE_KEY = `vsly-user-stats`;

export function useUserStats(account: AccountDetails) {
  const { get, post } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));

  const [, setStats, statsRef] = useDetachedState<UserStats>({ stats: [] });
  const [dedupKeys, setDedupKeys] = useSessionStorage(
    USER_STATS_STORAGE_KEY,
    [],
  );

  useAsyncEffect(async () => {
    if (account?.store?.alias) {
      const resp = (await get(routes.getUserStats())) as UserStats;
      if (resp?.stats) {
        setStats(resp);
        console.debug(`vsly`, `stats`, `getting user stats`, resp.stats);
      }
    }
  }, [account?.store?.alias]);

  const trackUserEvent = useDebouncedCallback(
    async (kind: UserStatsKind): Promise<UserStat[]> => {
      if (statsRef?.current?.stats && isGoalReached(kind)) {
        return Promise.resolve(statsRef.current.stats);
      }
      console.debug(`vsly`, `stats`, `tracking user stat: ${kind}`);
      const resp = await post(routes.trackUserEvent(), {
        kind,
      });

      setStats(resp);

      return Promise.resolve(resp.stats);
    },
    1000,
  );

  const isGoalReached = (kind: UserStatsKind): boolean =>
    statsRef?.current?.stats?.filter((s) => s.kind === kind)?.[0]
      ?.reachedTarget;

  const trackIfGoalNotReached = async (
    kind: UserStatsKind,
    ...dedupKey: string[]
  ): Promise<boolean> => {
    if (
      [...dedupKey]
        .map((key) => dedupKeys.includes(key))
        .filter((res) => res === true)?.length > 0
    ) {
      return true;
    }
    if (!isGoalReached(kind)) {
      const resp = await trackUserEvent(kind);
      if (dedupKey?.length > 0) setDedupKeys([...dedupKey, ...dedupKeys]);
      return resp?.filter((s) => s.kind === kind)?.[0]?.reachedTarget;
    }
    return true;
  };

  const isDuplicate = (dedupKey: string): boolean => {
    console.log(`stats`, dedupKey, dedupKeys.includes(dedupKey), dedupKeys);
    return dedupKeys.includes(dedupKey);
  };

  return {
    isGoalReached,
    trackUserEvent,
    trackIfGoalNotReached,
    isDuplicate,
  };
}

interface UserStats {
  stats: UserStat[];
}

export interface UserStat {
  kind: UserStatsKind;
  counter: number;
  reachedTarget: boolean;
}

export enum UserStatsKind {
  ____NA = `____NA`,
  IS_FIRST_TIME = `IS_FIRST_TIME`,
  SHOULD_SHOW_NEW_EXPERIENCE_ONBOARDING = `SHOULD_SHOW_NEW_EXPERIENCE_ONBOARDING`,
  SHOULD_SHOW_NEW_WIDGET_ONBOARDING = `SHOULD_SHOW_NEW_WIDGET_ONBOARDING`,
  SHOULD_SHOW_QUERY_BUILDER_ONBOARDING = `SHOULD_SHOW_QUERY_BUILDER_ONBOARDING`,
  SHOULD_SHOW_LOCATION_PICKER_ONBOARDING = `SHOULD_SHOW_LOCATION_PICKER_ONBOARDING`,
}
