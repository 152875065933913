import React, { useCallback } from 'react';
import { WidgetType } from '@/utils/definitions';
import {
  EditorDeclarativeBlock,
  getWidgetId,
} from '@/webapi/use-experience-api';
import {
  CheckoutNewComponent,
  ElementMessage,
  NewElementMessage,
} from '@/features/editor/context/use-device-preview';
import { CatalogAppSelector } from '@/features/editor/widgets/app-catalog';
import {
  TargetingWidget,
  TargetingWidgetProps,
} from '@/features/editor/widgets/targeting';
import { ChangelogWidget } from '@/features/editor/widgets/changelog';
import {
  ExperienceNameOrigin,
  ExperienceNameWidget,
} from '@/features/editor/widgets/name';
import {
  CustomWidget,
  CustomWidgetProps,
} from '@/features/editor/widgets/custom-widget';
import {
  CatalogApp,
  CatalogWidget,
  CatalogWidgetProps,
} from '@/webapi/use-widget-catalog-api';
import { CustomWidgetStep } from '@/features/editor/widgets/custom-widget/shared/context';
import { DEVICE_NAV_ID } from '@/features/editor/preview-navigation';
import { VisualEditorLegacyWidget } from '@/features/editor/widgets/visual-editor/legacy';
import {
  CompoundCodeEditorWidget,
  CompoundCodeEditorWidgetProps,
} from '@/features/editor/widgets/code-editors';
import {
  ChangeSelectorWidget,
  ChangeSelectorWidgetProps,
} from '@/features/editor/widgets/change-selector';
import {
  EDITOR_ACTIVE_BLOCK_CACHE,
  EDITOR_POST_PURCHASE_CACHE,
} from '@/components/hooks/use-cached-auto-save';
import {
  MoveElementWidget,
  MoveElementWidgetProps,
} from '@/features/editor/widgets/move-element';
import {
  FakeClickWidget,
  FakeClickWidgetProps,
} from '@/features/editor/widgets/fake-click';
import { useDetachedState } from '@/components/hooks/use-detached-state';
import {
  CodeTypeSelect,
  CodeTypeSelectProps,
} from '@/features/editor/widgets/code-editors/code-type-select';
import { VisualEditorWidget } from '@/features/editor/widgets/visual-editor';
import { PostPurchaseWidget } from '@/features/editor/widgets/post-purchase';
import { WidgetChange } from '@/pkg/sdk';
import { CheckoutInspectorNoMountPoints } from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-no-mount-points';
import {
  CheckoutAppsListProps,
  CheckoutInspectorApps,
} from '@/features/editor/widgets/checkout-extensibility/checkout-inspector-apps';
import { useTranspiler } from '@/features/editor/context/use-transpiler';
import { CheckoutExtensibilityAppsStep } from '@/features/editor/widgets/checkout-extensibility/context';
import {
  PageRedirectWidget,
  PageRedirectWidgetProps,
} from '@/features/editor/widgets/page-redirect';

export interface CurrentWidget {
  kind: WidgetType;
  props?: any;
}

export function useInspectorNavigation(
  appsCatalog: CatalogApp[],
  experienceId: string,
): InspectorNavigationHook {
  const { asCheckoutWidgetChange } = useTranspiler();
  const [currentWidget, setCurrentWidget, currentWidgetRef] =
    useDetachedState<CurrentWidget>({
      kind: WidgetType.CHANGELOG,
    });

  const gotoAppCatalog = ({
    kind,
    selector,
    styles,
    size,
    isInCart,
  }: NewElementMessage) => {
    setCurrentWidget({
      kind: WidgetType.APPS_CATALOG,
      props: { kind, selector, styles, size, isInCart },
    });
  };

  const gotoChangelog = async () => {
    await EDITOR_ACTIVE_BLOCK_CACHE.remove(experienceId);
    await EDITOR_POST_PURCHASE_CACHE.remove(experienceId);
    setCurrentWidget({ kind: WidgetType.CHANGELOG });
  };

  const gotoTargeting = (opts?: TargetingWidgetProps) => {
    setCurrentWidget({
      kind: WidgetType.TARGETING,
      props: { ...opts },
    });
  };

  const gotoExperienceName = (origin: ExperienceNameOrigin) => {
    setCurrentWidget({ kind: WidgetType.EXPERIENCE_NAME, props: { origin } });
  };

  const getAppFromWidgetChange = (
    change: EditorDeclarativeBlock,
  ): CatalogApp => {
    const widgetChange = change?.block?.value as WidgetChange;
    const widgetId = widgetChange?.widgetId;
    if (widgetId) {
      return appsCatalog.find(
        (app) => !!app.widgets.find((widget) => widget.id === widgetId),
      );
    }

    throw new Error(`Unable to find widget id on change: ${change}`);
  };

  const gotoCustomWidget = async (
    change: EditorDeclarativeBlock,
    initialStep?: CustomWidgetStep,
    catalogApp?: CatalogApp,
    widget?: CatalogWidget,
  ): Promise<void> => {
    catalogApp = catalogApp || getAppFromWidgetChange(change);
    const catalogWidget = catalogApp?.widgets?.find(
      ({ id }) => id === getWidgetId(change),
    );
    const savedWidget = fromSavedWidget(catalogWidget, change);
    widget = widget || savedWidget || catalogWidget;
    setCurrentWidget({
      kind: WidgetType.CUSTOM_WIDGET,
      props: { change, catalogApp, initialStep, widget } as CustomWidgetProps,
    });
    return Promise.resolve();
  };

  const fromSavedWidget = (
    catalogWidget: CatalogWidget,
    change: EditorDeclarativeBlock,
  ): CatalogWidget | undefined => {
    const { widgetProps, block } = change;
    if (widgetProps) {
      const widgetChange = block.value as WidgetChange;
      return {
        ...catalogWidget,
        blueprint: {
          ...catalogWidget?.blueprint,
          schema: widgetProps,
        },
        version: widgetChange.version,
      };
    }
    return undefined;
  };

  const hideOrShowNavigation = (currentWidget: CurrentWidget) => {
    if (WidgetType.CHANGELOG === currentWidget?.kind) {
      setDeviceSwitcherBehavior(true, true);
    } else if (WidgetType.PAGE_REDIRECT === currentWidget?.kind) {
      setDeviceSwitcherBehavior(true, false);
    } else if (WidgetType.POST_PURCHASE_EDITOR === currentWidget?.kind) {
      setDeviceSwitcherBehavior(true, false);
    } else {
      setDeviceSwitcherBehavior(false, false);
    }
  };

  const setDeviceSwitcherBehavior = (
    isVisible: boolean,
    allowNavigation: boolean,
  ) => {
    const switcher = document.querySelector(`#${DEVICE_NAV_ID}`) as HTMLElement;
    if (switcher) {
      switcher.style.opacity = isVisible ? `1` : `0`;
    }

    const pageNav = document.querySelector(
      `#${DEVICE_NAV_ID} > span`,
    ) as HTMLElement;
    if (pageNav) {
      pageNav.style.pointerEvents = allowNavigation ? `auto` : `none`;
      pageNav.style.opacity = allowNavigation ? `1` : `0.4`;
    }
  };

  const gotoCompoundEditor = (
    change: EditorDeclarativeBlock,
    isHtmlEditable: boolean,
  ) => {
    setCurrentWidget({
      kind: WidgetType.COMPOUND_EDITOR,
      props: {
        initialChange: change,
        isHtmlEditable,
      } as CompoundCodeEditorWidgetProps,
    });
  };

  const gotoLegacyVisualEditor = (
    msg: ElementMessage,
    change?: EditorDeclarativeBlock,
  ) => {
    const { selector, visualProps, html } = msg;
    setCurrentWidget({
      kind: WidgetType.LEGACY_VISUAL_EDITOR,
      props: { selector, visualProps, html, change },
    });
  };

  const gotoVisualEditor = (
    selector?: string,
    visualProps?: CatalogWidgetProps,
    change?: EditorDeclarativeBlock,
  ) => {
    setCurrentWidget({
      kind: WidgetType.VISUAL_EDITOR,
      props: { selector, visualProps, change },
    });
  };

  const gotoChangeSelector = (
    origChange: EditorDeclarativeBlock,
    deleteOnExit = false,
  ) => {
    setCurrentWidget({
      kind: WidgetType.CHANGE_SELECTOR,
      props: {
        originalChange: origChange,
        removeElementOnExit: deleteOnExit,
      } as ChangeSelectorWidgetProps,
    });
  };

  const gotoMoveElement = (
    origChange: EditorDeclarativeBlock,
    isNewChange?: boolean,
  ): void => {
    setCurrentWidget({
      kind: WidgetType.MOVE_ELEMENT,
      props: {
        originalChange: origChange,
        isNewChange,
      } as MoveElementWidgetProps,
    });
  };

  const gotoFakeClick = (origChange: EditorDeclarativeBlock): void => {
    setCurrentWidget({
      kind: WidgetType.FAKE_CLICK,
      props: {
        initialChange: origChange,
      } as FakeClickWidgetProps,
    });
  };

  const gotoCodeTypeSelector = (origChange: EditorDeclarativeBlock): void => {
    setCurrentWidget({
      kind: WidgetType.CODE_EDITOR_SELECTOR,
      props: {
        origChange,
      } as CodeTypeSelectProps,
    });
  };

  const gotoPostPurchaseEditor = () => {
    setCurrentWidget({
      kind: WidgetType.POST_PURCHASE_EDITOR,
      props: {},
    });
  };

  const gotoCheckoutNoMountPoints = () => {
    setCurrentWidget({
      kind: WidgetType.CHECKOUT_NO_MOUNT_POINTS,
      props: {},
    });
  };

  const gotoCheckoutAppListing = (msg: CheckoutNewComponent) => {
    const change = asCheckoutWidgetChange(null, null, msg);
    setCurrentWidget({
      kind: WidgetType.CHECKOUT_APPS,
      props: {
        initialStep: CheckoutExtensibilityAppsStep.APP_LISTING,
        change,
      } as CheckoutAppsListProps,
    });
  };

  const gotoCheckoutWidgetEdit = (change: EditorDeclarativeBlock) => {
    setCurrentWidget({
      kind: WidgetType.CHECKOUT_APPS,
      props: {
        initialStep: CheckoutExtensibilityAppsStep.WIDGET_STYLE,
        change,
      } as CheckoutAppsListProps,
    });
  };

  const gotoPageRedirectWidget = (change: EditorDeclarativeBlock) => {
    setCurrentWidget({
      kind: WidgetType.PAGE_REDIRECT,
      props: {
        originalChange: change,
      } as PageRedirectWidgetProps,
    });
  };

  const getCurrentWidget = useCallback(() => {
    hideOrShowNavigation(currentWidget);
    switch (currentWidget?.kind) {
      case WidgetType.APPS_CATALOG:
        return <CatalogAppSelector {...currentWidget.props} />;
      case WidgetType.TARGETING:
        return <TargetingWidget {...currentWidget?.props} />;
      case WidgetType.EXPERIENCE_NAME:
        return <ExperienceNameWidget {...currentWidget.props} />;
      case WidgetType.CUSTOM_WIDGET:
        return (
          <CustomWidget
            key={`cw${currentWidget.props.editorId}`}
            experienceId={experienceId}
            {...currentWidget.props}
          />
        );
      case WidgetType.LEGACY_VISUAL_EDITOR:
        return <VisualEditorLegacyWidget {...currentWidget.props} />;
      case WidgetType.VISUAL_EDITOR:
        return <VisualEditorWidget {...currentWidget.props} />;
      case WidgetType.COMPOUND_EDITOR:
        return <CompoundCodeEditorWidget {...currentWidget.props} />;
      case WidgetType.CHANGE_SELECTOR:
        return <ChangeSelectorWidget {...currentWidget.props} />;
      case WidgetType.MOVE_ELEMENT:
        return <MoveElementWidget {...currentWidget.props} />;
      case WidgetType.FAKE_CLICK:
        return <FakeClickWidget {...currentWidget.props} />;
      case WidgetType.CODE_EDITOR_SELECTOR:
        return <CodeTypeSelect {...currentWidget.props} />;
      case WidgetType.POST_PURCHASE_EDITOR:
        return <PostPurchaseWidget {...currentWidget.props} />;
      case WidgetType.CHECKOUT_NO_MOUNT_POINTS:
        return <CheckoutInspectorNoMountPoints {...currentWidget.props} />;
      case WidgetType.CHECKOUT_APPS:
        return <CheckoutInspectorApps {...currentWidget.props} />;
      case WidgetType.PAGE_REDIRECT:
        return <PageRedirectWidget {...currentWidget.props} />;
      default:
        return <ChangelogWidget />;
    }
  }, [currentWidget]);

  return {
    currentWidget,
    currentWidgetRef,
    gotoChangelog,
    gotoAppCatalog,
    gotoTargeting,
    gotoExperienceName,
    gotoCustomWidget,
    getCurrentWidget,
    gotoPostPurchaseEditor,
    gotoCompoundEditor,
    gotoVisualEditor,
    gotoLegacyVisualEditor,
    gotoChangeSelector,
    gotoMoveElement,
    gotoFakeClick,
    gotoCodeTypeSelector,
    gotoCheckoutNoMountPoints,
    gotoCheckoutAppListing,
    gotoCheckoutWidgetEdit,
    gotoPageRedirectWidget,
  };
}

export interface InspectorNavigationHook {
  currentWidget: CurrentWidget;
  currentWidgetRef: React.MutableRefObject<CurrentWidget>;
  gotoChangelog: () => void;
  gotoCheckoutNoMountPoints: () => void;
  gotoAppCatalog: (msg: NewElementMessage) => void;
  gotoCompoundEditor: (
    change: EditorDeclarativeBlock,
    isHtmlEditable: boolean,
  ) => void;
  gotoTargeting: (opts?: TargetingWidgetProps) => void;
  gotoExperienceName: (origin: ExperienceNameOrigin) => void;
  gotoCustomWidget: (
    change: EditorDeclarativeBlock,
    initialStep?: CustomWidgetStep,
    catalogApp?: CatalogApp,
    widget?: CatalogWidget,
  ) => Promise<void>;
  gotoPageRedirectWidget: (change: EditorDeclarativeBlock) => void;
  getCurrentWidget: () => JSX.Element;
  gotoLegacyVisualEditor: (
    msg: ElementMessage,
    change?: EditorDeclarativeBlock,
  ) => void;
  gotoVisualEditor: (
    selector: string,
    visualProps?: CatalogWidgetProps,
    change?: EditorDeclarativeBlock,
  ) => void;
  gotoPostPurchaseEditor: () => void;
  gotoChangeSelector: (
    origChange: EditorDeclarativeBlock,
    deleteOnExit?: boolean,
  ) => void;
  gotoMoveElement: (
    origChange: EditorDeclarativeBlock,
    isNewChange?: boolean,
  ) => void;
  gotoFakeClick: (origChange: EditorDeclarativeBlock) => void;
  gotoCodeTypeSelector: (origChange: EditorDeclarativeBlock) => void;
  gotoCheckoutAppListing: (msg: CheckoutNewComponent) => void;
  gotoCheckoutWidgetEdit: (change: EditorDeclarativeBlock) => void;
}
