export const PREVIEW_THEME_ID = `preview_theme_id`;

export function isString(x: any): boolean {
  return x.constructor.name === `String`;
}

export function truncate(str, n) {
  return str?.length > n ? `${str.substr(0, n - 1)}...` : str;
}

export function formatEnum(value: string, capital?: boolean): string {
  let out = ``;

  if (value) {
    out = `${value}`.replaceAll(`_`, ` `);
    if (capital) {
      out = capitalize(out.toLowerCase());
    }
  }
  if (out === `ALL CATEGORIES`) {
    return `ALL COLLECTIONS`;
  }
  if (out === `MINI CART`) {
    return `CART DRAWER`;
  }
  return out;
}

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function capitalize(str: string) {
  const arr = str.split(` `);
  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(` `);
}

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export function deepEqual(object1: any, object2: any) {
  if (!object1 && !object2) return true;
  if (!object1 && !!object2) return false;
  if (!!object1 && !object2) return false;
  const keys1 = Object.keys(object1).sort((a, b) => a.localeCompare(b));
  const keys2 = Object.keys(object2).sort((a, b) => a.localeCompare(b));
  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object: any) {
  return object != null && typeof object === `object`;
}
