/* eslint-disable no-promise-executor-return */
import React, { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { EditorDeclarativeBlock } from '@/webapi/use-experience-api';
import { CatalogApp, CatalogWidget } from '@/webapi/use-widget-catalog-api';
import {
  CustomWidgetContext,
  CustomWidgetStep,
  newCustomWidgetContext,
} from '@/features/editor/widgets/custom-widget/shared/context';
import { BlueprintSelect } from '@/features/editor/widgets/custom-widget/blueprint-select';
import { BlueprintCustomize } from '@/features/editor/widgets/custom-widget/blueprint-customize';
import { BlueprintStyle } from '@/features/editor/widgets/custom-widget/blueprint-style';
import { assignMostPopularFontAndStoreStyles } from '@/features/editor/widgets/custom-widget/popular-font';
import { EditorContext } from '@/features/editor/context/editor-context';
import { TEMP_NO_SELECTOR } from '@/features/editor/widgets/changelog/placeholder';
import { AnchorOrigin } from '@/features/editor/context/use-device-preview';
import { createNewElementMessageFromOrigChange } from '@/features/editor/context/use-transpiler';
import { LocationPicker } from '@/features/editor/widgets/shared/location-picker';
import { AllMutationKind } from '@/pkg/sdk';
import { EDITOR_ACTIVE_BLOCK_CACHE } from '@/components/hooks/use-cached-auto-save';

export interface CustomWidgetProps {
  change: EditorDeclarativeBlock;
  catalogApp: CatalogApp;
  widget?: CatalogWidget;
  initialStep?: CustomWidgetStep;
  experienceId: string;
  cachedChange?: EditorDeclarativeBlock;
}

export function CustomWidget(props: CustomWidgetProps) {
  const {
    experienceState: { currentExperience },
  } = useContext(EditorContext);

  const [cache, setCache] = useState<EditorDeclarativeBlock | undefined | null>(
    null,
  );

  useAsyncEffect(async () => {
    const cachedValue = await EDITOR_ACTIVE_BLOCK_CACHE.get(
      currentExperience.id,
    );

    setCache(cachedValue);
  }, []);

  if (cache === null) {
    return <div />;
  }

  return <CustomWidgetLoaded {...props} />;
}

function CustomWidgetLoaded({
  change,
  catalogApp,
  initialStep,
  widget,
  experienceId,
  cachedChange,
}: CustomWidgetProps) {
  const {
    devicePreview: {
      editorState: { mostPopularFont },
      anchor,
    },
    resources: { storeStyle },
    inspectorNav: { gotoAppCatalog },
  } = useContext(EditorContext);
  const firstStep = initialStep || CustomWidgetStep.SELECT;
  const patchedApp = assignMostPopularFontAndStoreStyles(
    catalogApp,
    mostPopularFont,
    storeStyle,
  );

  const ctx = newCustomWidgetContext(
    change,
    patchedApp,
    widget,
    firstStep,
    experienceId,
    cachedChange,
  );

  const { currentStep, setNewChange } = ctx;

  const hasNoPlacement = change?.block?.selector === TEMP_NO_SELECTOR;

  const onSelectorPicked = (
    selector: string,
    kind: AllMutationKind,
    isInCart?: boolean,
  ) => {
    change.block.selector = selector;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (change.block.value?.htmlKind) change.block.value.htmlKind = kind;
    change.isInCart = isInCart;
    setTimeout(() => {
      anchor(selector, AnchorOrigin.NEW_ELEMENT);
    }, 250);
    setNewChange(change);
  };

  const onBackFromPicker = () => {
    const newChange = JSON.parse(
      JSON.stringify(change),
    ) as EditorDeclarativeBlock;
    newChange.block.selector = TEMP_NO_SELECTOR;
    gotoAppCatalog(createNewElementMessageFromOrigChange(newChange));
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasNoPlacement ? (
        <LocationPicker
          onContinue={onSelectorPicked}
          onBack={onBackFromPicker}
          title="Choose a location for the new section"
          backCaption="< Back to widgets"
        />
      ) : (
        <CustomWidgetContext.Provider value={ctx}>
          {currentStep === CustomWidgetStep.SELECT && <BlueprintSelect />}
          {currentStep === CustomWidgetStep.CUSTOMIZE && (
            <BlueprintCustomize
              updateFlow={initialStep === CustomWidgetStep.CUSTOMIZE}
            />
          )}
          {currentStep === CustomWidgetStep.STYLE && <BlueprintStyle />}
        </CustomWidgetContext.Provider>
      )}
    </>
  );
}
