import { useContext, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import {
  EditorDeclarativeBlock,
  PlacementKind,
} from '@/webapi/use-experience-api';
import { useComplexState } from '@/utils/use-complex-state';
import { QBItemSelection } from '@/components/query-builder/models';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PageRedirectChange, StickinessMode } from '@/pkg/sdk';
import { DeviceType } from '@/utils/definitions';
import { PageRedirectOptions } from '@/webapi/use-widget-catalog-api';

export function usePageRedirect(originalChange: EditorDeclarativeBlock) {
  const {
    experienceControls,
    inspectorNav,
    devicePreview,
    deviceNavigation,
    experienceState: {
      setTargetingPlacement,
      currentVariantIdx,
      upsertEditorChange,
    },
  } = useContext(EditorContext);

  const initialVariantId = useMemo(() => currentVariantIdx, []);
  const [isSourceModalVisible, setIsSourceModalVisible] = useState(false);
  const [props, setProps] = useComplexState(
    originalChange?.pageRedirectOpts ||
      ({
        stickinessMode: `none`,
        retainQueryParams: true,
      } as PageRedirectOptions),
  );
  const [newChange, setNewChange] = useComplexState(originalChange);

  const setSourceRules = async (rules: Array<QBItemSelection>) => {
    setProps((draft) => {
      draft.sourceUrlProps = [...rules];
    });

    if (rules?.length > 0) {
      const foundUrl = rules
        .map((rule) => rule.values?.find((val) => isValidUrl(val.value)))
        ?.find((val) => !!val);
      if (foundUrl?.value && isValidUrl(foundUrl.value)) {
        deviceNavigation?.navigateTo(cleanUrl(foundUrl?.value));
      }
    }
  };

  const setTargetUrl = (url: string) => {
    setProps((draft) => {
      draft.targetUrl = cleanUrl(url);
    });
  };

  const setRetainQueryParams = (retainQueryParams: boolean) => {
    setProps((draft) => {
      draft.retainQueryParams = retainQueryParams;
    });
  };

  const setStickinessMode = (stickinessMode: StickinessMode) => {
    setProps((draft) => {
      draft.stickinessMode = stickinessMode;
    });
  };

  const onNext = () => {
    inspectorNav.gotoTargeting({
      disablePlacementPicker: true,
      disableThemesPicker: true,
    });
  };

  const targetUrlInitialValue = (_1: string, _2: DeviceType) =>
    props?.targetUrl || ``;

  useEffect(() => {
    if (initialVariantId !== currentVariantIdx) {
      // Reload the change log to get the new variant change
      inspectorNav.gotoChangelog();
    }
  }, [currentVariantIdx, initialVariantId]);

  useEffect(() => {
    setNewChange(mutateNewChangeFn);
    if (props?.sourceUrlProps) {
      const newTargeting = {
        kind: PlacementKind.OTHER,
        other: [...props.sourceUrlProps],
      };
      setTargetingPlacement(newTargeting, true);
      setTimeout(() => {
        setTargetingPlacement(newTargeting, true);
      }, 500);
    }
    upsertEditorChange(produce(newChange, mutateNewChangeFn));
  }, [props]);

  const mutateNewChangeFn = (draft: EditorDeclarativeBlock) => {
    draft.pageRedirectOpts = props;
    draft.block.value = {
      destUrl: props.targetUrl,
      redirectAfter: 1,
      retainQueryParams: props.retainQueryParams,
      stickinessMode: props.stickinessMode,
    } as PageRedirectChange;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      devicePreview.looseFocus();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return {
    props,
    newChange,
    initialVariantId,
    isSourceModalVisible,
    targetUrlInitialValue,
    setIsSourceModalVisible,
    onNext,
    setSourceRules,
    setTargetUrl,
    setRetainQueryParams,
    setStickinessMode,
    experienceControls,
  };
}

function cleanUrl(url: string) {
  return url.trim();
}

function isValidUrl(urlString) {
  const urlPattern = new RegExp(
    `^(https?:\\/\\/)?` + // validate protocol
      `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|` + // validate domain name
      `((\\d{1,3}\\.){3}\\d{1,3}))` + // validate OR ip (v4) address
      `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` + // validate port and path
      `(\\?[;&a-z\\d%_.~+=-]*)?` + // validate query string
      `(\\#[-a-z\\d_]*)?$`,
    `i`,
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
}
