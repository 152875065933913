import * as React from 'react';
import { BsCheck } from 'react-icons/bs';
import styled from 'styled-components';
import { ReactChild, useEffect, useState } from 'react';

export interface CircularCheckmarkProps extends Props {
  onChange?: (selected: boolean) => void;
  caption?: string | ReactChild;
  disabled?: boolean;
}

interface Props {
  size?: string;
  selected?: boolean;
  disabled?: boolean;
  square?: boolean;
}

export function CircularCheckmark(props: CircularCheckmarkProps) {
  const { selected, onChange, caption, disabled } = props;

  const [isSelected, setSelected] = useState(selected);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  const onClick = () => {
    if (disabled) return;
    setSelected(!isSelected);
    onChange && onChange(!isSelected);
  };

  return (
    <Wrapper
      className="checkmark-top-wrapper"
      {...props}
      selected={isSelected}
      onClick={onClick}
    >
      <CheckmarkWrapper
        className="checkmark-wrapper"
        {...props}
        selected={isSelected}
      >
        <Checkmark
          className="checkmark-content"
          {...props}
          selected={isSelected}
        >
          <BsCheck size={20} />
        </Checkmark>
      </CheckmarkWrapper>
      {!!caption && <span className="caption">{caption}</span>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  user-select: none;
  .caption {
    user-select: none;
    font-family: Inter, serif;
    margin-left: 1.2rem;
    font-size: 1.4rem;
    color: ${(p: Props) => (p.selected ? `#2C3539` : `#777E81`)};
  }

  opacity: ${(p: Props) => (p.disabled ? `0.4` : `1`)};
  :hover {
    cursor: ${(p: Props) => (p.disabled ? `default` : `pointer`)};
    opacity: ${(p: Props) => (p.disabled ? `0.4` : `0.8`)};
  }

  :active {
    cursor: default;
    opacity: ${(p: Props) => (p.disabled ? `0.4` : `0.6`)};
  }
`;

const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p: Props) => p?.size || `2rem`};
  width: ${(p: Props) => p?.size || `2rem`};

  cursor: pointer;

  background-color: #f1f1f1;
  border: 1px solid #adb7c1;
  border-radius: ${(p) => (p.square ? `25%` : `50%`)};
`;

const Checkmark = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 85%;
  width: 85%;
  background-color: #0086ff;
  color: white;
  border-radius: ${(p) => (p.square ? `25%` : `50%`)};

  opacity: ${(p) => (p.selected ? 1 : 0)};
  transition: opacity 0.3s ease-in;

  && > * {
    transition: transform 0.3s ease-out;
    transition-delay: ${(p) => (p.selected ? `0.1s` : `0`)};
    transform: scale(${(p) => (p.selected ? 1 : 0)});
  }
`;
